export const enums = {
    "YES": "YES",
    "NO": "NO",
    "CONTRACTOR": "CONTRACTOR",
    "ACCOUNTANT": "ACCOUNTANT",
    "ACCOUNTANTS_CLIENT": "ACCOUNTANTS_CLIENT",
    "CLIENT_PAYS": "CLIENT_PAYS",
    "ACCOUNTANT_PAYS": "ACCOUNTANT_PAYS",
    "BRONZE": 99,
    "SILVER": 129,
    "GOLD": 199,
    "GOLDSPECIAL": 99,
}
