import React from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fade from "@material-ui/core/Fade";

const ThankYouPage = () => {
    const classes = useStyles();

    return (
        <Fade in={true} timeout={2000}>
            <div>
                <CssBaseline/>
                <Grid container direction={"column"} alignContent={"center"} alignItems={"center"} justify={"center"}
                      className={classes.container}>
                    <Grid item>
                        <Typography variant={'h2'}>Thank You for submitting your information!</Typography>
                    </Grid>
                </Grid>
            </div>
        </Fade>
    );
};

export default ThankYouPage;

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        height: '100vh'
    }
}));

