import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/core/styles';
import {AppContextProvider} from "./context/AppContext";
import {Switch} from "react-router";
import LoadingScreen from "./utils/sharedComponents/LoadingScreen";
import UserDetailsView from "./Views/UserDetailsDashboard/UserDetailsView";
import AccountantsClientDashboard from "./Views/AccountantsClientDashboard/AccountantsClientDashboard";
import AccountantSignUp from "./Views/AccountantDashboard/AccountantSignUp";
import HomePage from "./Views/HomePage/HomePage";
import UserProfile from "./Views/CommonComponents/UserProfile";
import ContractorFormView from "./Views/Contractor/ContractorFormView";
// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import Billing from "./Views/Billing/Billing";
import Pricing from "./Views/Pricing/Pricing";
import Contact from "./Views/ContactForm/Contact";
import ReactPixel from 'react-facebook-pixel';
import Review1099 from "./Views/Review1099/Review1099";
import {Untitled} from "./Views/HomePage/TestHomePage";
import TagManager from 'react-gtm-module'


const SignUpCool = lazy(() => import( "./Views/LoginScreens/SignUpCool"))
const Calculator1099 = lazy(() => import( "./Views/Blog/Calculator1099"))
const Login = lazy(() => import( "./Views/LoginScreens/Login"))
const ResetPassword = lazy(() => import("./Views/LoginScreens/ResetPassword"))
const AccountantsDashboard = lazy(() => import  ("./Views/AccountantDashboard/AccountantDashboard"))


const App = () => {

    //<editor-fold desc="Tracking">
    //FB PIXEL
    const advancedMatching = {em: 'some@email.com'}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init('285141396933816', options);

    // Google Ads
    const TRACKING_ID = "G-JNSDDZDT6S";

    ReactGA.initialize([
        {
            trackingId: "UA-207435680-1",
            // gaOptions: {...}, // optional
            // gtagOptions: {...}, // optional
        },
        {
            trackingId: "G-JNSDDZDT6S",
            // gaOptions: {...}, // optional
            // gtagOptions: {...}, // optional
        },

    ]);

    // ReactGA.initialize("your GA measurement id");
    ReactGA.send("pageview");

    // const tagManagerArgs = {
    //     gtmId: 'GTM-MGRR9ZH',
    //     dataLayer: {
    //         userId: '001',
    //         userProject: 'project'
    //     }
    // }
    //
    // TagManager.initialize(tagManagerArgs)

    //</editor-fold>

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    primary: {
                        // light: will be calculated from palette.primary.main,
                        main: '#00a6a4',
                        contrastText: '#ffffff'
                        // dark: will be calculated from palette.primary.main,
                        // contrastText: will be calculated to contrast with palette.primary.main
                    },
                    secondary: {
                        main: '#1B8EB1'
                    },
                    neutral: {
                        main: '#ffffff',
                        gray: '#f4f6f6',
                        gray2: '#adadad',
                    }
                },
                typography: {
                    fontFamily: "Comfortaa, calibri",
                    h1: {
                        fontSize: '3rem'
                    },
                    h2: {
                        fontSize: '2.8rem'
                    },
                    h3: {
                        fontSize: '2rem'
                    },
                    h4: {
                        fontSize: '1.5rem'
                    },
                },
                action: {
                    hover: '#1B8EB1',
                    active: '#1B8EB1',

                }
            })
    )


    return (
        <AppContextProvider>
            <ThemeProvider theme={responsiveFontSizes(theme)}>
                <div className={"App"} style={{display: 'block'}}>
                    <Router>
                        <Suspense fallback={<LoadingScreen/>}>
                            <Switch>
                                <Route exact path="/" render={() => (<HomePage/>)}/>
                                <Route exact path="/login" render={() => (<Login/>)}/>
                                <Route exact path="/createaccount" render={() => (<AccountantSignUp/>)}/>
                                <Route exact path="/dashboard" render={() => <AccountantsDashboard/>}/>
                                <Route exact path="/billing" render={() => <Billing/>}/>
                                <Route exact path="/review1099/:uid" render={() => <Review1099/>}/>
                                <Route exact path="/pricing" render={() => <Pricing/>}/>
                                <Route exact path="/contact" render={() => <Contact/>}/>
                                <Route exact path="/aclient/:uid" render={() => <UserDetailsView/>}/>
                                <Route exact path="/:uid/profile" render={() => <UserProfile/>}/>

                                <Route exact path="/client/:uid" render={() => <AccountantsClientDashboard/>}/>
                                <Route exact path="/client/:uid/dashboard"
                                       render={() => <AccountantsClientDashboard/>}/>
                                {/*<Route exact path="/client/:uid/profile" render={() => <UserProfile/>}/>*/}

                                <Route exact path="/contractor/:uid" render={() => <UserDetailsView/>}/>
                                <Route exact path="/contractorform/:uid" render={() => <ContractorFormView/>}/>

                                <Route exact path="/1099-calculator" render={() => (<Calculator1099/>)}/>
                                <Route exact path="/resetPassword" render={() => (<ResetPassword/>)}/>
                                <Route exact path="/learn-to-automate-1099NEC-AW" render={() => (<Untitled/>)}/>
                            </Switch>
                        </Suspense>
                    </Router>
                </div>
            </ThemeProvider>
        </AppContextProvider>
    )
};

export default App;
