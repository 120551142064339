import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from "@material-ui/core/Tooltip";
import {
    getArrayOfObjectsWithRemovedItem,
    getObjectFromArrayThatMatchesValue,
    updateUserInDb
} from "../../../utils/sharedFunctions";
import {AppContext} from "../../../context/AppContext";
import firebaseApp from "../../../Firebase/firebaseApp";
import {enums} from "../../../utils/enums";
import {useHistory} from "react-router";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});


export default function SummaryTable({contractorsReadyToFileTop, setOpenModal, accountTypeViewing, showToast}) {
    const classes = useStyles();
    const {state: {userDataObject, listOfUsersClients}, dispatch} = useContext(AppContext);
    const [contractorsReadyToFile, setContractorsReadyToFile] = useState([])
    const history = useHistory()
    useEffect(() => {
        setContractorsReadyToFile(contractorsReadyToFileTop)
    }, [contractorsReadyToFileTop])


    async function removeClientFromReadyToFileArray(accountantsClientUid, contractorUidToRemove) {
        switch (accountTypeViewing) {
            case  enums.ACCOUNTANT:
                let newClientObject = getObjectFromArrayThatMatchesValue(listOfUsersClients, 'uid', accountantsClientUid)
                newClientObject.requestedFilings = newClientObject.requestedFilings.filter(contractorUid => {
                    return contractorUid !== contractorUidToRemove
                })
                try {
                    await updateUserInDb(dispatch, newClientObject, await firebaseApp.auth().currentUser.getIdToken())
                    const contractorsArrayWithRemovedItem = getArrayOfObjectsWithRemovedItem(contractorsReadyToFile, 'uid', contractorUidToRemove)

                    setContractorsReadyToFile(contractorsArrayWithRemovedItem)
                } catch (e) {
                    showToast("error", "Something went wrong, please try again!")
                }
                break;

            case enums.ACCOUNTANTS_CLIENT:
                const arrayWithRemovedItem = getArrayOfObjectsWithRemovedItem(contractorsReadyToFile, 'uid', contractorUidToRemove)
                setContractorsReadyToFile(arrayWithRemovedItem)
                break;
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell><strong>Contractor Name</strong></TableCell>
                        <TableCell align="right"><strong>Paid</strong></TableCell>
                        <TableCell align="right"><strong></strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contractorsReadyToFile
                    && contractorsReadyToFile.map((contractor) => (
                        <TableRow key={contractor.uid}>
                            <TableCell component="th" scope="row">
                                <Tooltip title={'Reject'}><IconButton onClick={async () => {
                                    await removeClientFromReadyToFileArray(contractor.accountantClientUid, contractor.uid)
                                }}><ClearIcon/></IconButton></Tooltip>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {contractor.firstName} {contractor.lastName}
                            </TableCell>
                            <Tooltip title={"click to edit"}>
                                <TableCell
                                    align="right"
                                    style={parseFloat(contractor.paid) === 0 ? {
                                        color: "red",
                                        textDecoration: 'underline'
                                    } : {color: "black"}}
                                    onClick={() => {
                                        history.push('/contractor/' + contractor.uid)
                                    }}
                                >
                                    ${contractor.paid}
                                </TableCell>
                            </Tooltip>

                                <TableCell>
                                    <Button onClick={() => {
                                        window.open(process.env.REACT_APP_CLIENT_URL + '/review1099/' + contractor.uid)
                                    }} variant={"text"} color={"primary"}>
                                        Review 1099
                                    </Button>
                                </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
