import React, {useContext, useEffect} from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {enums} from "../../utils/enums";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import {
    deduceFiledStatus, getIndexOfObjectInArray,
    inviteAccountantsClientToEFileBuddy,
    isAccountantsClientReadyToFile,
    isW9Complete
} from "../../utils/sharedFunctions";
import PropTypes from "prop-types";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";
import DashboardIcon from '@material-ui/icons/Dashboard';
import IconButton from "@material-ui/core/IconButton";
import ArchiveIcon from '@material-ui/icons/Archive';
import * as firebase from "firebase";
import {AppContext} from "../../context/AppContext";
import Container from "@material-ui/core/Container";

const CustomerOverviewTable = ({
                                   accountTypeViewing,
                                   listOfUsersToRender,
                                   routeToPage,
                                   sendReminderEmail,
                                   remindPayorToFile,
                                   dispatch,
                                   showToast,
                                   setClientUidToChange,
                                   setShouldOpenUpdateAmountModal,
                                   setOpenSummaryModal,
                               }) => {
    const classes = useStyles()
    const {state: {userDataObject, listOfUsersClients}} = useContext(AppContext);
    if (!listOfUsersToRender || !userDataObject) {
        return null
    }


    function incrementRemindersSentOnTheClient(clientRow) {
        const indexOfClient = getIndexOfObjectInArray(listOfUsersClients, "uid", clientRow.uid)
        listOfUsersClients[indexOfClient].remindersSent += 1
        dispatch({listOfUsersClients: listOfUsersClients})
    }

    function generateCorrectButton(clientRow) {
        if (clientRow.filed === enums.NO) {
            return (
                <Button
                    onClick={() => {
                        remindPayorToFile(clientRow, clientRow.accountType, dispatch, showToast).then(() => {
                            incrementRemindersSentOnTheClient(clientRow)
                            showToast('success', 'Reminder Sent');
                        }).catch(error => {
                            showToast('Error', 'Something went wrong, please try again');
                        })

                    }}
                    size={"small"} variant={'outlined'} className={classes.remindBtn}>
                    Remind to submit filings
                </Button>
            )
        } else {
            return (<Button
                onClick={() => {
                    routeToPage(clientRow)
                }}
                size={"small"} variant={'outlined'} className={classes.remindBtn}>
                View 1099
            </Button>)
        }
    }

    function deduceButtonToRender(clientRow, accountTypeViewing) {
        if (accountTypeViewing !== enums.ACCOUNTANT) return null
        else if (!clientRow.hasConfirmedInformation) {
            return (
                <TableCell align="left">
                    <Button
                        onClick={async () => {
                            inviteAccountantsClientToEFileBuddy(
                                clientRow.uid,
                                await firebase.auth().currentUser.getIdToken()
                            ).then(() => {
                                    incrementRemindersSentOnTheClient(clientRow)
                                    showToast("success", "Invite Sent")
                                }
                            ).catch(e => {
                                showToast("error", "Something went wrong, please try again!")
                            })
                        }}
                        size={"small"} variant={'outlined'} className={classes.remindBtn}>
                        Invite To Platform
                    </Button>
                </TableCell>
            )
        } else if (!isAccountantsClientReadyToFile(clientRow.requestedFilings)) {
            return (
                <TableCell align="left">
                    {generateCorrectButton(clientRow)}

                </TableCell>
            )
        } else {
            return (
                <TableCell align="left">
                    <Button onClick={() => setOpenSummaryModal(true, clientRow)} size={"small"}
                            variant={'outlined'} className={classes.remindBtn}>
                        Approve
                    </Button>
                </TableCell>
            )
        }
    }


    function createButton(clientRow) {
        function deduceButtonText(clientRow) {
            if (clientRow.filed === enums.YES) {
                return "View PDF"
            } else if (userDataObject.requestedFilings.includes(clientRow.uid)) {
                return "Filing Requested"
            } else {
                return "Request W9"
            }
        }

        return (
            <Button size={"small"}
                    variant={'outlined'}
                    className={classes.remindBtn}
                    disabled={userDataObject.requestedFilings.includes(clientRow.uid)}
                    onClick={() => {
                        if (clientRow.filed === enums.NO) {
                            sendReminderEmail(clientRow, enums.CONTRACTOR, dispatch, showToast)
                            clientRow.remindersSent = clientRow.remindersSent + 1
                        } else if (clientRow.filed === enums.YES) {
                            window.open(clientRow.priorFilings[clientRow.priorFilings.length-1].downloadUrl,'_blank')
                        }
                    }}
            >
                {deduceButtonText(clientRow)}
            </Button>)
    }

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {/*<TableCell></TableCell>*/}
                        <TableCell><strong>Name</strong></TableCell>
                        {/*<TableCell align="left"><strong>Last Name</strong></TableCell>*/}
                        <TableCell align="left"><strong>Email</strong></TableCell>
                        <TableCell align="left"><strong>EIN</strong></TableCell>
                        {enums.ACCOUNTANT === accountTypeViewing &&
                        <TableCell align="left"><strong>Filed</strong></TableCell>}
                        {enums.ACCOUNTANT === accountTypeViewing &&
                        <TableCell align="left"><strong>Pending</strong></TableCell>}
                        {enums.ACCOUNTANTS_CLIENT === accountTypeViewing &&
                        <TableCell align="left"><strong>Paid</strong></TableCell>}
                        <TableCell align="left"><strong>Reminders Sent</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listOfUsersToRender.map((clientRow) => (
                        <TableRow key={clientRow.uid}>
                            {/*<TableCell><Tooltip title={'Archive Client'}>*/}
                            {/*    <IconButton onClick={() => archiveClient(clientRow)}>*/}
                            {/*        <ArchiveIcon/>*/}
                            {/*    </IconButton>*/}
                            {/*</Tooltip></TableCell>*/}
                            <TableCell align="left">
                                <Tooltip title={'View Details'}>

                                    <a className={classes.textLink}
                                       onClick={() => routeToPage(clientRow)}>
                                        {clientRow.firstName + " " + clientRow.lastName}</a>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="left">{clientRow.email}</TableCell>
                            <TableCell align="left">{clientRow.ein}</TableCell>
                            {enums.ACCOUNTANT === accountTypeViewing &&
                            <TableCell
                                align="left">{clientRow.contractorFilingsSentToIRS && clientRow.contractorFilingsSentToIRS.length}</TableCell>}
                            {enums.ACCOUNTANT === accountTypeViewing &&
                            <TableCell
                                align="left">{clientRow.contractors.length - clientRow.contractorFilingsSentToIRS.length}</TableCell>}
                            {enums.ACCOUNTANTS_CLIENT === accountTypeViewing
                            && <Tooltip title={'click to edit'} placement={"top"}>
                                <TableCell align="left"
                                           className={classes.textLink && classes.amount}
                                           client-uid={clientRow.uid}
                                           onClick={(e) => {
                                               setClientUidToChange(e.currentTarget.getAttribute('client-uid'))
                                               setShouldOpenUpdateAmountModal(true);
                                           }}>${clientRow.paid}</TableCell>
                            </Tooltip>}

                            <TableCell align="left">{clientRow.remindersSent}</TableCell>

                            {deduceButtonToRender(clientRow, accountTypeViewing)}

                            {accountTypeViewing === enums.ACCOUNTANTS_CLIENT
                            && <TableCell align="left">
                                {createButton(clientRow)}
                            </TableCell>
                            }

                            <TableCell align="left">
                                <Tooltip title={'View Details'}>
                                    <IconButton size={"small"}
                                                variant={'outlined'}
                                                className={classes.remindBtn}
                                                onClick={() => routeToPage(clientRow)}
                                    >
                                        <DashboardIcon/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    );
};

export default CustomerOverviewTable;

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: '100%',
        width: '100%',
        minWidth: 1000,
        maxWidth: '99vw',
    },
    tableContainer: {
        width: '100%',
        minWwidth: '100%'
    },
    remindBtn: {
        minWidth: '60%',
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
        // color: 'white',
        margin: 10,
        borderRadius: 30
    },
    textLink: {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.primary.light,
        cursor: 'pointer',
    },
    amount: {
        cursor: 'text',
    },

}))

CustomerOverviewTable.propTypes = {
    accountTypeViewing: PropTypes.string.isRequired,
    listOfUsersToRender: PropTypes.array.isRequired,
    sendReminderEmail: PropTypes.func.isRequired,
    remindPayorToFile: PropTypes.func.isRequired,
    routeToPage: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    showToast: PropTypes.func.isRequired,
    setClientUidToChange: PropTypes.func,
    setShouldOpenUpdateAmountModal: PropTypes.func,
    setOpenSummaryModal: PropTypes.func.isRequired,
    archiveClient: PropTypes.func.isRequired,
};
