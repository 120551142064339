import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import SummaryTable from "./SummaryTable";
import loadingGif from "../../../assets/loadingGreen.gif";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {enums} from "../../../utils/enums";
import Dialog from "@material-ui/core/Dialog";
import {request1099FromAccountant} from "../../../utils/sharedFunctions";
import firebaseApp from "../../../Firebase/firebaseApp";
import PropTypes from "prop-types";
import SummaryModal from "./SummaryModal";

const SummaryDialog = ({
                           openModal, setOpenModal, title, areAnyOfTheContractorsPaid0, contractorsReadyToFile,
                           accountTypeViewing, shouldShowPayment, setShowBilling, userDataObject, dispatch, showToast
                       }) => {

    async function handleConfirm() {
        contractorsReadyToFile.forEach(contractor => {

            if (!userDataObject.requestedFilings) {
                userDataObject.requestedFilings = []
            }

            const indexOfContractor = userDataObject.requestedFilings.findIndex(value => contractor.uid === value)
            if (indexOfContractor === -1) {
                userDataObject.requestedFilings = [...userDataObject.requestedFilings, contractor.uid]
            }
        })

        try {
            await request1099FromAccountant(dispatch, userDataObject, await firebaseApp.auth().currentUser.getIdToken());

            showToast('success', 'Request Sent!')
            setOpenModal(false)
        } catch (e) {
            showToast('error', 'Request Failed!')
        }
    }


    return (
        <Dialog
            maxWidth={false}
            open={openModal}
            onClose={() => setOpenModal(false)}
            onBackdropClick={() => setOpenModal(false)}
            aria-labelledby="form-dialog-title">
            <section>
                <DialogTitle id="form-dialog-title">
                    <strong>
                        {title}
                        {areAnyOfTheContractorsPaid0() &&
                        <Typography style={{color: 'red'}}>
                            Make sure all contractors are paid non 0 amounts
                        </Typography>}
                    </strong>
                </DialogTitle>
                <DialogContent>
                    {contractorsReadyToFile
                    && <SummaryTable contractorsReadyToFileTop={contractorsReadyToFile} setOpenModal={setOpenModal}
                                     accountTypeViewing={accountTypeViewing} showToast={showToast}/>}

                    {!contractorsReadyToFile
                    && <img src={loadingGif}
                            alt={'loading_gif'}
                            style={{width: 200, height: 200}}
                    />}

                    {shouldShowPayment()
                    && <Grid container alignContent={'center'} alignItems={"center"} justify={"center"}
                             style={{marginTop: 30}}>
                        <Grid item>
                            {/*<StripePremade showToast={showToast} setOpenModal={setOpenModal}/>*/}
                            <Button variant={"contained"} color={"primary"}
                                    onClick={() => setShowBilling(true)}>Approve</Button>
                        </Grid>
                    </Grid>}

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)} color="secondary">
                        Cancel
                    </Button>

                    {!shouldShowPayment()
                    &&
                    <Button
                        disabled={areAnyOfTheContractorsPaid0()}
                        onClick={async () => {
                            switch (accountTypeViewing) {
                                case enums.ACCOUNTANT:
                                    break
                                case enums.ACCOUNTANTS_CLIENT:
                                    await handleConfirm()
                                    break
                            }
                        }}
                        variant={'contained'}
                        color="primary">
                        Submit
                    </Button>
                    }

                </DialogActions>
            </section>


        </Dialog>
    );
};

export default SummaryDialog;


SummaryDialog.propTypes = {
    showToast: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    accountTypeViewing: PropTypes.string.isRequired,
    contractorsReadyToFile: PropTypes.array.isRequired,
    setOpenModal: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    showPayments: PropTypes.bool.isRequired,
    areAnyOfTheContractorsPaid0: PropTypes.func.isRequired,
    shouldShowPayment: PropTypes.func.isRequired,
    setShowBilling: PropTypes.func.isRequired,
    userDataObject: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};
