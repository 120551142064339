import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useGetUserData from "../../utils/hooks/useGetUserData";
import {AppContext} from "../../context/AppContext";
import Typography from "@material-ui/core/Typography";
import Menu from "../Menu/Menu";
import useProtectRoute from "../../utils/hooks/useProtectRoute";
import AppBarSpacer from "../../utils/sharedComponents/AppBarSpacer";
import UserInformationForm from "../../utils/modals/UserInformationForm";
import Toast from "../../utils/sharedComponents/Toast";
import {deduceCorrectDashboardToPushTo, getQueryStringParams, updateUserInDb} from "../../utils/sharedFunctions";
import AccountantsClientMenuListItems from "../AccountantsClientDashboard/accountantsClientMenuListItems";
import Button from "@material-ui/core/Button";
import firebaseApp from "../../Firebase/firebaseApp";
import AccountantMenuListItems from "../AccountantDashboard/accountantMenuListItems";
import {useHistory, useLocation} from "react-router";
import {func} from "prop-types";
import AccountantProfile from "../ProfilePages/AccountantProfile";
import AccountantsClientProfile from "../ProfilePages/AccountantsClientProfile";
import * as firebase from "firebase";

const UserProfile = () => {
    useProtectRoute()
    const classes = useStyles();
    const {state: {userDataObject}, dispatch} = useContext(AppContext);
    const [toastStatus, setToastStatus] = useState(null);
    const [clientObject, setClientObject] = useState(null);
    const history = useHistory()
    const location = useLocation()
    const [showRequiredFields, setShowRequiredFields] = useState(false)

    async function handleChangeInformation() {
        dispatch({loadingBarProgress: 40})
        await updateUserInDb(dispatch, clientObject, await firebaseApp.auth().currentUser.getIdToken())
        dispatch({loadingBarProgress: 100})
        showToast('success', "Information Updated")
        history.push(deduceCorrectDashboardToPushTo(userDataObject.accountType, userDataObject.uid))
    }

    function showToast(toastType, toastMessage) {
        setToastStatus({
            toastType: toastType,
            toastMessage: toastMessage,
            date: Date()
        })
    }

    function determineMenuList() {
        switch (userDataObject.accountType) {
            case "ACCOUNTANTS_CLIENT":
                return <AccountantsClientMenuListItems/>
            case "ACCOUNTANT":
                return <AccountantMenuListItems/>
        }
    }

    function determineProfileToShow() {
        switch (userDataObject.accountType) {
            case "ACCOUNTANTS_CLIENT":
                return <AccountantsClientProfile
                    shouldShowRequiredFields={location.state && location.state.requiredFields}
                    showToast={showToast}
                    userDataObject={userDataObject}
                    setClientObject={setClientObject}
                    handleChangeInformation={handleChangeInformation}/>
            case "ACCOUNTANT":
                return <AccountantProfile
                    shouldShowRequiredFields={location.state && location.state.requiredFields}
                    showToast={showToast}
                    userDataObject={userDataObject}
                    setClientObject={setClientObject}
                    handleChangeInformation={handleChangeInformation}/>
        }
    }


    if (!userDataObject) {
        history.push('/login')
        return null
    }

    function navigateHome() {
        history.push(deduceCorrectDashboardToPushTo(userDataObject.accountType, firebase.auth().currentUser.uid))
    }

    return (
        <div>
            <Menu listOfItemsToRender={determineMenuList()} setOpenAddCreditsModal={navigateHome}/>
            <AppBarSpacer/>
            {userDataObject &&
            <Grid container direction={"column"} alignContent={"center"} alignItems={"center"} justify={"center"}>
                <Grid item>
                    <Typography variant={"h2"}>Your Profile</Typography>
                </Grid>
                <Grid item style={{maxWidth: '95%'}}>
                    {determineProfileToShow()}
                </Grid>
            </Grid>
            }
            {toastStatus ? <Toast key={toastStatus.date} toastStatus={toastStatus}/> : null}
        </div>
    );
};

export default UserProfile;

const useStyles = makeStyles((theme) => ({}));

