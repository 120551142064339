import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import UserDetailsTable from "./UserDetailsTable";
import TextField from "@material-ui/core/TextField";
import {deduceFiledStatus, updateUserInDb} from "../../utils/sharedFunctions";
import firebaseApp from "../../Firebase/firebaseApp";
import UpdateSingleNumericFieldModal from "../../utils/modals/UpdateSingleNumericFieldModal";
import {AppContext} from "../../context/AppContext";
import {enums} from "../../utils/enums";
import Tooltip from "@material-ui/core/Tooltip";


export default function ClientDetailsTop({clientToShow, showToast, setClientToEdit}) {
    const classes = useStyles();
    const [notes, setNotes] = useState('')
    const [shouldOpenUpdateNotesModal, setShouldOpenUpdateNotesModal] = useState(false);
    const [openUpdatePaidModal, setOpenUpdatePaidModal] = useState(false);
    const [amountPaid, setAmountPaid] = useState(clientToShow.paid);
    const {state: {listOfUsersClients}, dispatch} = useContext(AppContext);

    useEffect(() => {
        if (clientToShow) {
            const clientsInitialNotes = clientToShow.clientNotes
            setNotes(clientsInitialNotes)
        }
    }, [clientToShow])

    async function handleSubmitChangeNotes() {
        //TODO: based on the client type update the relevant client with the amount
        clientToShow.clientNotes = notes
        try {
            await updateUserInDb(dispatch, clientToShow, await firebaseApp.auth().currentUser.getIdToken())
            dispatch({showLoadingGif: false})
            setShouldOpenUpdateNotesModal(false)
            showToast('success', 'Notes updated!')
        } catch (e) {
            showToast("error", "Something went wrong, please try again!")
        }
    }

    async function handleSubmitChangeAmount() {
        const updatedContractorObject = listOfUsersClients.filter(user => user.uid === clientToShow.uid)[0]
        updatedContractorObject.paid = amountPaid
        try {
            await updateUserInDb(dispatch, updatedContractorObject, await firebaseApp.auth().currentUser.getIdToken())
            dispatch({showLoadingGif: false})
            setOpenUpdatePaidModal(false)
            showToast('success', 'Amount updated!')
        } catch (e) {
            showToast("error", "Something went wrong, please try again!")
        }
    }

    return (
        <div>
            <Grid container spacing={1} alignContent={"center"} alignItems={"center"} justify={"center"}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.gridItem}>
                    <UserDetailsTable clientToShow={clientToShow} showToast={showToast}
                                      setClientToEdit={setClientToEdit}/>
                </Grid>
                {
                    enums.ACCOUNTANTS_CLIENT === clientToShow.accountType
                    && <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
                        <Paper className={classes.gridItem}>
                            <Typography variant={"body1"}>Filed<br/><br/></Typography>
                            <Typography
                                variant={"h4"}><strong>{clientToShow.contractorFilingsSentToIRS.length}</strong></Typography>
                        </Paper>
                    </Grid>
                }

                {
                    enums.ACCOUNTANTS_CLIENT === clientToShow.accountType
                    && <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
                        <Paper className={classes.gridItem}>
                            <Typography variant={"body1"}>Pending<br/><br/></Typography>
                            <Typography
                                variant={"h4"}><strong>{clientToShow.contractors.length - clientToShow.contractorFilingsSentToIRS.length}</strong></Typography>
                        </Paper>
                    </Grid>
                }

                <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
                    <Tooltip title={"Click to edit"}>

                        <Paper className={classes.gridItem} style={{cursor: 'text'}}
                               onClick={() => setOpenUpdatePaidModal(true)}>
                            <Typography variant={"body1"}>Paid<br/><br/></Typography>
                            <Typography variant={"body1"}
                                        style={{
                                            textDecoration: 'underline'
                                        }}>
                                <strong>${clientToShow.paid}</strong>
                            </Typography>
                        </Paper>
                    </Tooltip>
                </Grid>

                <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
                    <Paper className={classes.gridItem}>
                        <Typography variant={"body1"}>Reminders sent<br/><br/></Typography>
                        <Typography variant={"h4"}><strong>{clientToShow.remindersSent}</strong></Typography>
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <Paper className={classes.gridItem && classes.notes} onClick={() => {
                        setShouldOpenUpdateNotesModal(true)
                    }}>
                        <Typography variant={"body1"}>Client Notes<br/><br/></Typography>
                        <Typography variant={"body1"}>{clientToShow.clientNotes}</Typography>
                    </Paper>
                </Grid>
            </Grid>

            <UpdateSingleNumericFieldModal
                title={"Update notes"}
                shouldOpenUpdateFieldModal={shouldOpenUpdateNotesModal}
                setShouldOpenUpdateFieldModal={setShouldOpenUpdateNotesModal}
                handleSubmit={handleSubmitChangeNotes}
                initialValue={notes}
                label={"notes"}
                setInitialValue={setNotes}
                typeToBeInputted={'string'}
                errorText={"please input a text value"}
                multiline={true}
            />

            <UpdateSingleNumericFieldModal
                title={"Update amount paid to contractor"}
                shouldOpenUpdateFieldModal={openUpdatePaidModal}
                setShouldOpenUpdateFieldModal={setOpenUpdatePaidModal}
                handleSubmit={handleSubmitChangeAmount}
                initialValue={amountPaid}
                label={"amount paid"}
                setInitialValue={setAmountPaid}
                errorText={"Input numeric value"}
                typeToBeInputted={'number'}
            />

        </div>

    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    gridItem: {
        backgroundColor: theme.palette.neutral.white,
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: '100%',
        minHeight: '25vh',
        // minWidth: 100
    },
    notes: {
        cursor: 'text',
        minHeight: '25vh',
        padding: 10,
        minWidth: 100
    }
}));
