import React from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Icon from "@material-ui/core/Icon";
import PDF from "@material-ui/icons/PictureAsPdf";
import Button from "@material-ui/core/Button";
import {saveAs} from 'file-saver';

const PriorFilingsShowingContractorData = ({client}) => {
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell><strong>Year</strong></TableCell>
                        <TableCell><strong>Paid</strong></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {client.priorFilings.map((priorFiling) => (
                        <TableRow key={priorFiling.downloadUrl}>
                            <TableCell component="th" scope="row">
                                <Icon>
                                    <PDF/>
                                </Icon>
                            </TableCell>

                            <TableCell component="th" scope="row">
                                {priorFiling.filingYear}
                            </TableCell>

                            <TableCell component="th" scope="row">
                                {priorFiling.paid}
                            </TableCell>

                            <TableCell>
                                <Button variant={"text"} onClick={() => {
                                    saveAs(priorFiling.downloadUrl, client.email + "_" + priorFiling.filingYear)
                                }}>
                                    Download
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PriorFilingsShowingContractorData;
