import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import {useHistory} from "react-router";
import logo from "../../assets/logoGreen.svg";


export default function ButtonAppBar() {
    const classes = useStyles();
    const history = useHistory()

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="static" color={'white'}>
                <Toolbar>
                    {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">*/}
                    {/*    <MenuIcon />*/}
                    {/*</IconButton>*/}
                    <img src={logo} alt={'logo'} style={{marginRight: 10, maxHeight: '100%', cursor: 'pointer'}} onClick={()=>history.push('/')}/>
                    <Typography variant="h6" className={classes.title} onClick={()=>history.push('/')}>
                        eFile<strong>Buddy</strong>
                    </Typography>

                    <Button color="inherit" variant={"text"}
                            onClick={() => history.push('1099-calculator')}>1099 calculator</Button>
                    <Button color="inherit" variant={"text"}
                            onClick={() => history.push('contact')}>Contact us</Button>
                    <Button color="inherit" variant={"text"}
                            onClick={() => history.push('pricing')}>Pricing</Button>
                    <Button className={classes.loginButton} color="inherit" variant={"outlined"}
                            onClick={() => history.push('login')}>Login</Button>
                </Toolbar>
            </AppBar>
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        cursor: 'pointer'
    },
    formField: {},
    loginButton: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        margin: 10,
        borderRadius: 30
    },

}));

