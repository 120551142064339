import React, {useContext, useEffect, useState} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Icon from "@material-ui/core/Icon";
import PDF from "@material-ui/icons/PictureAsPdf";
import Button from "@material-ui/core/Button";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {
    getAllContractorsForClient,
    getObjectFromArrayThatMatchesValue,
} from "../../../utils/sharedFunctions";
import {saveAs} from 'file-saver';

const PriorFilingsShowingAggregatedContractorData = ({client, showToast}) => {
    const [contractorsByYears, setContractorsByYears] = useState({})

    function extractNeededDataForTable(contractor, year) {
        const priorFilingsForGivenYear = getObjectFromArrayThatMatchesValue(contractor.priorFilings, 'filingYear', year)

        return {
            firstName: contractor.firstName,
            lastName: contractor.lastName,
            year: year,
            paid: priorFilingsForGivenYear.paid,
            downloadUrl: priorFilingsForGivenYear.downloadUrl
        }
    }

    function sortContractorsByYears(contractors) {
        let contractorsByYears = {}
        contractors.map(contractor => {
            contractor.priorFilings
            && contractor.priorFilings.map(priorFiling => {
                if (!Object.keys(contractorsByYears).includes(priorFiling.filingYear.toString())) {
                    contractorsByYears[priorFiling.filingYear] = []
                    contractorsByYears[priorFiling.filingYear].push(extractNeededDataForTable(contractor, priorFiling.filingYear))
                } else {
                    contractorsByYears[priorFiling.filingYear].push(extractNeededDataForTable(contractor, priorFiling.filingYear))
                }
            })
        })

        return contractorsByYears
    }

    useEffect(() => {
        async function getContractorData() {
            try {
                const allContractors = await getAllContractorsForClient(client.uid)
                const contractorsByYears = sortContractorsByYears(allContractors)
                setContractorsByYears(contractorsByYears)
            } catch (e) {
                showToast("error", "Failed, please refresh")
            }
        }

        client && getContractorData()
    }, [client])

    return (
        contractorsByYears ?
            Object.keys(contractorsByYears).map(year => {
                const contractorsForYear = contractorsByYears[year]
                return (
                    <Accordion expanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography><strong>{year}</strong></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell><strong>Year</strong></TableCell>
                                            <TableCell><strong>Paid</strong></TableCell>
                                            <TableCell><strong>Name</strong></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {contractorsForYear.map((contractor) => (
                                            <TableRow key={contractor.downloadUrl}>
                                                <TableCell component="th" scope="row">
                                                    <Icon>
                                                        <PDF/>
                                                    </Icon>
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {contractor.year}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {contractor.paid}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {contractor.firstName + " " + contractor.lastName}
                                                </TableCell>

                                                <TableCell>
                                                    <Button variant={"text"} onClick={() => {
                                                        saveAs(contractor.downloadUrl,
                                                            contractor.firstName + "_"
                                                            + contractor.lastName + "_"
                                                            + contractor.year)
                                                    }}>
                                                        Download
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                )
            }) : null
    );
};

export default PriorFilingsShowingAggregatedContractorData;
