import React, {useContext, useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {enums} from "../enums";
import PropTypes from 'prop-types';
import firebaseApp from "../../Firebase/firebaseApp";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {AppContext} from "../../context/AppContext";
import {isNum} from "../sharedFunctions";
import {countries, country_names_only, states, states_codes_only} from "../lists";
import AutoCompleteSimple from "../../Views/CommonComponents/AutoCompleteSimple";


const UserInformationForm = ({showToast, clientTypeToAdd, existingUserDataObject, setClientObject, shouldShowRequiredFields}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [ein, setEin] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('United States');
    const [shouldErrorEin, setShouldErrorEin] = useState(false);
    const [shouldErrorPhone, setShouldErrorPhone] = useState(false);
    const [shouldErrorZip, setShouldErrorZip] = useState(false);
    const classes = useStyles();
    const {state: {shouldOpenAddClientModal, canSubmitAddClientForm, showLoadingGif}, dispatch} = useContext(AppContext);
    const [displayCompanyFields, setDisplayCompanyFields] = useState(
        clientTypeToAdd === enums.ACCOUNTANT
        || clientTypeToAdd === enums.ACCOUNTANTS_CLIENT)

    //<editor-fold desc="Effects">

    function checkIfNumberAndLength(string, requiredLength) {
        return isNum(string) && (string.length === requiredLength)
    }

    function isEinCorrect() {
        if (ein !== '') {
            setShouldErrorEin(!checkIfNumberAndLength(ein, 9))
        } else {
            setShouldErrorEin(false)
        }
    }

    function isPhoneCorrect() {
        if (phone !== '') {
            setShouldErrorPhone(!checkIfNumberAndLength(phone, 10))
        } else {
            setShouldErrorPhone(false)
        }
    }

    function isZipCorrect() {
        if (zipCode !== '') {
            setShouldErrorZip(!checkIfNumberAndLength(zipCode, 5))
        } else {
            setShouldErrorZip(false)
        }
    }


    useEffect(() => {}, [state])

    useEffect(() => {
        isEinCorrect()
        isPhoneCorrect()
        isZipCorrect()
    }, [ein, phone, zipCode])


    useEffect(() => {
        if (firstName !== ''
            && (email !== '')
            || (!shouldErrorZip && zipCode !== '')
            || (!shouldErrorPhone && phone !== '')
            || (!shouldErrorEin && ein !== '')
        ) {
            dispatch({canSubmitAddClientForm: true})
        }
    }, [firstName, email, zipCode, shouldErrorZip, phone, shouldErrorPhone, ein, shouldErrorEin])

    useEffect(() => {
        if ((shouldErrorZip && zipCode !== '')
            || (shouldErrorPhone && phone !== '')
            || (shouldErrorEin && ein !== '')) {
            dispatch({canSubmitAddClientForm: false})
        }
    }, [zipCode, ein, phone, shouldErrorZip, shouldErrorPhone, shouldErrorEin])

    useEffect(() => {
        // If fields are already present set them
        if (existingUserDataObject) {
            setFirstName(existingUserDataObject.firstName)
            setLastName(existingUserDataObject.lastName)
            setEmail(existingUserDataObject.email)
            setEin(existingUserDataObject.ein)
            setPhone(existingUserDataObject.phone)
            setAddressLine1(existingUserDataObject.addressLine1)
            setAddressLine2(existingUserDataObject.addressLine2)
            setCity(existingUserDataObject.city)
            setState(existingUserDataObject.state)
            setZipCode(existingUserDataObject.zipCode)
            setCountry(existingUserDataObject.country)
        }
    }, [existingUserDataObject])


    useEffect(() => {
        // every time one of the fields in the form is typed into set the object in the parent
        let client = {}
        if (existingUserDataObject) {
            client = existingUserDataObject
            client.firstName = firstName
            client.lastName = lastName
            client.email = email
            client.phone = phone
            client.ein = ein
            client.addressLine1 = addressLine1
            client.addressLine2 = addressLine2
            client.city = city
            client.state = state
            client.zipCode = zipCode
            client.country = country
            setClientObject(client)
        } else {
            setClientObject(createObjectForClientType(displayCompanyFields))
        }
    }, [firstName, lastName, email, phone, ein, addressLine1, addressLine2,
        city, state, zipCode, country])

    //</editor-fold>

    //<editor-fold desc="Server Calls">


    //</editor-fold>

    function createObjectForClientType(displayCompanyFields) {
        // when we are rendering the information form we have to pass in the type of the client we are trying to create
        // and this method creates an object based on that fact using the information from the form
        if (clientTypeToAdd === enums.ACCOUNTANTS_CLIENT) {
            let client = {
                firstName: firstName,
                lastName: lastName,
                isCompany: displayCompanyFields,
                email: email,
                ein: ein,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                contractors: [],
                requestedFilings: [],
                state: state,
                zipCode: zipCode,
                country: country,
                phone: phone,
                //TODO: ATTENTION!!! this is defaulting and if we reuse it for updates it will overwrite whatever is in the DB
                filed: enums.NO,
                paid: 0,
                remindersSent: 0,
                accountType: enums.ACCOUNTANTS_CLIENT,
                clientNotes: '',
                contractorFilingsSentToIRS: []
            }
            return client
        } else if (clientTypeToAdd === enums.CONTRACTOR) {
            return {
                firstName: firstName,
                lastName: lastName,
                isCompany: displayCompanyFields,
                email: email,
                phone: phone,
                ein: ein,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                state: state,
                zipCode: zipCode,
                country: country,
                filed: enums.NO,
                paid: 0,
                remindersSent: 0,
                accountantClientUid: firebaseApp.auth().currentUser ? firebaseApp.auth().currentUser.uid : undefined,
                accountType: enums.CONTRACTOR,
                clientNotes: ''
            }
        } else {
            throw new Error("Something went wrong, got client type that is unaccounted for")
        }
    }


    return (
        <div>
            <form>
                <Grid container spacing={3}>
                    <Grid item>
                        <Typography
                            variant={"body1"}>{displayCompanyFields ? 'Want to add an individual?' : 'Want to add a company?'}</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant={"outlined"}
                                size={"small"}
                                onClick={() => setDisplayCompanyFields(!displayCompanyFields)}
                        >{displayCompanyFields ? "Show Individual Fields" : "Show Company Fields"}</Button>
                    </Grid>
                </Grid>

                <TextField
                    margin="dense"
                    id="firstName"
                    label={displayCompanyFields ? "Company name" : "First Name"}
                    type="text"
                    fullWidth
                    required
                    error={firstName === '' && shouldShowRequiredFields}
                    helperText={firstName === '' ? "this field is required" : ''}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                {!displayCompanyFields
                && <TextField
                    margin="dense"
                    error={shouldShowRequiredFields && email===''}
                    id="lastName"
                    label="Last Name"
                    type="text"
                    fullWidth
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />}
                <TextField
                    margin="dense"
                    id="email"
                    label="Email"
                    type="text"
                    fullWidth
                    required
                    value={email}

                    onChange={(e) => setEmail(e.target.value)}
                    helperText={email === '' ? "this field is required" : ''}
                    error={email === '' && shouldShowRequiredFields}
                />
                <TextField
                    margin="dense"
                    id="phone"
                    label="Phone"
                    type="text"
                    fullWidth
                    error={shouldErrorPhone && shouldShowRequiredFields}
                    helperText={shouldErrorPhone ? "Field has to be 10 digits long" : ''}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="eIN"
                    label={displayCompanyFields ? "Company EIN" : "Individual SSN or ITIN"}
                    type="text"
                    fullWidth
                    value={ein}
                    onClickAway={() => alert("test")}
                    error={shouldErrorEin && shouldShowRequiredFields}
                    helperText={shouldErrorEin ? "Field has to be 9 digits long" : ''}
                    onChange={(e) => {
                        setEin(e.target.value)
                    }}
                />
                <TextField
                    margin="dense"
                    id="address"
                    label="Address"
                    type="text"
                    fullWidth
                    value={addressLine1}
                    error={shouldShowRequiredFields && addressLine1===''}
                    onChange={(e) => setAddressLine1(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="addres2"
                    label="Address2"
                    type="text"
                    fullWidth
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="city"
                    label="City"
                    type="text"
                    fullWidth
                    error={shouldShowRequiredFields && city===''}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                />

                {states_codes_only
                && <AutoCompleteSimple
                    options={states_codes_only} value={state} setValue={setState}
                    shouldShowRequiredFields={shouldShowRequiredFields}
                />}
                <TextField
                    margin="dense"
                    id="zip"
                    label="Zip"
                    type="text"
                    fullWidth
                    error={shouldErrorZip && shouldShowRequiredFields}
                    helperText={(shouldErrorZip && shouldShowRequiredFields) ? "Field has to be 5 digits long" : ''}
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                />
                {country_names_only
                && <AutoCompleteSimple
                    options={country_names_only} value={country} setValue={setCountry}
                    shouldShowRequiredFields={shouldShowRequiredFields}
                />}
                {/*<AutoComplete setOptionValue={setCountry} optionValue={country} defaultValue={"United States"}*/}
                {/*              labelToShow={'Choose Country'} nameOfTheFieldToDisplay={"label"}*/}
                {/*              valuesToShow={countries}/>*/}
            </form>

        </div>
    );
};

export default UserInformationForm;

const useStyles = makeStyles(theme => ({}))


UserInformationForm.propTypes = {
    clientTypeToAdd: PropTypes.string.isRequired,
    setClientObject: PropTypes.func.isRequired,
};

