import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import CheckIcon from '@material-ui/icons/Check';
import Typography from "@material-ui/core/Typography";
import {func} from "prop-types";
import Button from "@material-ui/core/Button";
import {useTheme} from "@material-ui/core";

export default function PricingPackages({setPackageToBuy, setOpenModal, isPublic}) {
    const theme = useTheme()
    function packageToBuy(packageToBuy) {
        return (
            <Button variant={"contained"} color={"primary"}
                    onClick={() => {
                        setPackageToBuy(packageToBuy)
                        setOpenModal(true)
                    }}>Buy {packageToBuy}</Button>
        )
    }

    function createData(name, bronze, silver, gold) {
        return {name, bronze, silver, gold};
    }

    function valueAndCheckmark() {
        return (
            <Grid container direction={'column'}>
                <Grid item>
                    <CheckIcon fontSize={"large"} color={"primary"}/>
                </Grid>
                <Grid item>
                    <Typography variant={"body1"}>(value 125$)</Typography>
                </Grid>
            </Grid>)
    }

    function launchSpecial(regular, special) {
        return (
            <Grid container direction={'column'}>
                <Grid item>
                    <Typography color={"primary"}><strong><s>${regular}</s></strong></Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"body1"}>Launch Special ${special}</Typography>
                </Grid>
            </Grid>);
    }
    function createPrice(price) {
return <Typography color={"primary"}><strong>{price}</strong></Typography>
    }

    const rows = [
        createData('Annual Accountant Portal', valueAndCheckmark(), valueAndCheckmark(), valueAndCheckmark()),
        createData('Annual Employers Portal', valueAndCheckmark(), valueAndCheckmark(), valueAndCheckmark()),
        createData('Included Filing Credits', createPrice(15), createPrice(30), createPrice(50)),
        createData('Additional Filing Credit Price', createPrice('$5'), createPrice('$4'), createPrice('$3')),
        createData('', launchSpecial(325, 99), launchSpecial(400, 129), launchSpecial(500, 199)),
    ];

    !isPublic && rows.push(createData('', packageToBuy('bronze'), packageToBuy('silver'), packageToBuy('gold')))


    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center"><Typography
                            variant={"h5"}><strong>Bronze</strong></Typography></TableCell>
                        <TableCell align="center"><Typography
                            variant={"h5"}><strong>Silver</strong></Typography></TableCell>
                        <TableCell align="center"><Typography
                            variant={"h5"}><strong>Gold</strong></Typography></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            style={{height: 100}}
                        >
                            <TableCell component="th" scope="row">
                                <Typography variant={"body1"}><strong>{row.name}</strong></Typography>
                            </TableCell>

                            <TableCell align="center">{row.bronze}</TableCell>
                            <TableCell align="center">{row.silver}</TableCell>
                            <TableCell align="center">{row.gold}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
