import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {AppContext} from "../../context/AppContext";
import {useHistory} from "react-router";
import Fade from "@material-ui/core/Fade";
import {enums} from "../../utils/enums";
import PropTypes, {func} from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import CustomerOverviewTable from "./CustomerOverviewTable";
import './styles.css'
import useTheme from "@material-ui/core/styles/useTheme";
import Container from "@material-ui/core/Container";

export default function TableOverviewPerReadinessToFile({
                                                            accountTypeViewing,
                                                            setShouldOpenUpdateAmountModal,
                                                            setClientUidToChange,
                                                            sendReminderEmail,
                                                            remindPayorToFile,
                                                            showToast,
                                                            readyToFile,
                                                            notReadyToFile,
                                                            filed,
                                                            setOpenSummaryModal,
                                                            archiveClient
                                                        }) {

    const history = useHistory()
    const classes = useStyles();
    const {state: {listOfUsersClients}, dispatch} = useContext(AppContext);
    const theme = useTheme()
    const [value, setValue] = React.useState('1');
    useEffect(() => {
    }, [readyToFile])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function calculateTotalPaidAmount() {
        let totalAmountPaid = 0
        listOfUsersClients.forEach(client => totalAmountPaid += parseFloat(client.paid))
        return totalAmountPaid
    }

    function routeToPage(clientRow) {
        if (accountTypeViewing === enums.ACCOUNTANT) {
            history.push("/aclient/" + clientRow.uid)
        } else if (accountTypeViewing === enums.ACCOUNTANTS_CLIENT) {
            history.push("/contractor/" + clientRow.uid)
        }
    }

    return (
        <React.Fragment>
            <Fade in={true} timeout={2000}>
                <div style={{marginTop: 20}}>
                    {/*TODO check spanning table to fix the alignment and not have to create two tables https://material-ui.com/components/tables/#spanning-table*/}
                    <Container maxWidth={"lg"} style={{width: '100%', minWidth: '100%'}}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {enums.ACCOUNTANT === accountTypeViewing &&
                                        <TableCell><strong>Clients Summary</strong></TableCell>}
                                        {enums.ACCOUNTANTS_CLIENT === accountTypeViewing &&
                                        <TableCell><strong>Contractors Summary</strong></TableCell>}
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"><strong>Filed</strong></TableCell>
                                        <TableCell align="right"><strong>Paid</strong></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={0}>
                                        {/*TODO Just doing the lenght is stupid there will likely be active/inactive clients*/}
                                        <TableCell component="th"
                                                   scope="row">{listOfUsersClients.length}</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell
                                            align="right">{filed.length}</TableCell>
                                        <TableCell align="right">${calculateTotalPaidAmount()}</TableCell>
                                        <TableCell align="right">{}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>


                        <section style={{minWidth: '100%', width: '100%', marginTop: 40}}>
                            <TabContext value={value}>
                                <AppBar position="static">
                                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"
                                          className={'customTab'} style={{backgroundColor: 'white'}}
                                          TabIndicatorProps={{
                                              style: {
                                                  height: 4,
                                                  backgroundColor: theme.palette.primary.main
                                              }
                                          }}>
                                        <Tab className={classes.tab}
                                             label={`Not Ready To File (${notReadyToFile.length})`}
                                             value={'1'}/>
                                        <Tab className={classes.tab} label={`Ready To File (${readyToFile.length})`}
                                             value={'2'}/>
                                        <Tab className={classes.tab} label={`Filed (${filed.length})`} value={'3'}/>
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={'1'} index={1} className={classes.tabPanel}>
                                    <CustomerOverviewTable showToast={showToast} dispatch={dispatch}
                                                           accountTypeViewing={accountTypeViewing}
                                                           listOfUsersToRender={notReadyToFile}
                                                           routeToPage={routeToPage}
                                                           sendReminderEmail={sendReminderEmail}
                                                           remindPayorToFile={remindPayorToFile}
                                                           setShouldOpenUpdateAmountModal={setShouldOpenUpdateAmountModal}
                                                           setClientUidToChange={setClientUidToChange}
                                                           setOpenSummaryModal={setOpenSummaryModal}
                                                           archiveClient={archiveClient}

                                    />
                                </TabPanel>
                                <TabPanel value={'2'} index={2} className={classes.tabPanel}>
                                    <CustomerOverviewTable showToast={showToast}
                                                           dispatch={dispatch}
                                                           accountTypeViewing={accountTypeViewing}
                                                           listOfUsersToRender={readyToFile}
                                                           routeToPage={routeToPage}
                                                           sendReminderEmail={sendReminderEmail}
                                                           remindPayorToFile={remindPayorToFile}
                                                           setShouldOpenUpdateAmountModal={setShouldOpenUpdateAmountModal}
                                                           setClientUidToChange={setClientUidToChange}
                                                           setOpenSummaryModal={setOpenSummaryModal}
                                                           archiveClient={archiveClient}

                                    />
                                </TabPanel>

                                <TabPanel value={'3'} index={3} className={classes.tabPanel}>
                                    <CustomerOverviewTable showToast={showToast} dispatch={dispatch}
                                                           accountTypeViewing={accountTypeViewing}
                                                           listOfUsersToRender={filed}
                                                           routeToPage={routeToPage}
                                                           sendReminderEmail={sendReminderEmail}
                                                           remindPayorToFile={remindPayorToFile}
                                                           setShouldOpenUpdateAmountModal={setShouldOpenUpdateAmountModal}
                                                           setClientUidToChange={setClientUidToChange}
                                                           setOpenSummaryModal={setOpenSummaryModal}
                                                           archiveClient={archiveClient}

                                    />
                                </TabPanel>
                            </TabContext>
                        </section>
                    </Container>
                </div>
            </Fade>
        </React.Fragment>

    );
}
const useStyles = makeStyles(theme => ({
    tabPanel: {
        paddingLeft: 0, paddingRight: 0
    },
    table: {
        minWidth: 1000,
        maxWidth: '99vw',
    },
    remindBtn: {
        minWidth: '60%',
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
        // color: 'white',
        margin: 10,
        borderRadius: 30
    },
    textLink: {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.primary.light,
        cursor: 'pointer',
    },
    amount: {
        cursor: 'text',
    },
    tab: {
        color: theme.palette.primary.light,
        // padding: 0

    }
}))

TableOverviewPerReadinessToFile.propTypes = {
    accountTypeViewing: PropTypes.string.isRequired,
    sendReminderEmail: PropTypes.func.isRequired,
    remindPayorToFile: PropTypes.func.isRequired,
    setOpenSummaryModal: PropTypes.func.isRequired,
    setShouldOpenUpdateAmountModal: PropTypes.func,
    setClientUidToChange: PropTypes.func,
    archiveClient: PropTypes.func.isRequired,
    readyToFile: PropTypes.array.isRequired,
    notReadyToFile: PropTypes.array.isRequired,
    filed: PropTypes.array.isRequired
};
