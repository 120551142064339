import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../context/AppContext";
import Toast from "../../utils/sharedComponents/Toast";
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router";
import Menu from "../Menu/Menu";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";
import useProtectRoute from "../../utils/hooks/useProtectRoute";
import AddClientModal from "../../utils/modals/AddClientModal";
import {enums} from "../../utils/enums";
import TableOverviewPerReadinessToFile from "../CommonComponents/TableOverviewPerReadinessToFile";
import AppBarSpacer from "../../utils/sharedComponents/AppBarSpacer";
import Grid from "@material-ui/core/Grid";
import {
    addContractorToDb, archiveClientInDb,
    doesUserHaveAllFieldsNeededToFile, getAccountantPublicData,
    isW9Complete,
    sendReminderEmail,
    updateUserInDb
} from "../../utils/sharedFunctions";
import firebaseApp from "../../Firebase/firebaseApp";
import UpdateSingleNumericFieldModal from "../../utils/modals/UpdateSingleNumericFieldModal";
import AccountantsClientMenuListItems from "./accountantsClientMenuListItems";
import useGetUserData from "../../utils/hooks/useGetUserData";
import Button from "@material-ui/core/Button";
import SummaryModal from "./SummaryModal/SummaryModal";
import firebase from "firebase/app";
import FirstTimeLogIn from "./FirstTimeLogIn/FirstTimeLogIn";
import LoadingScreen from "../../utils/sharedComponents/LoadingScreen";

const AccountantsClientDashboard = () => {
    useProtectRoute()
    useGetUserData()

    const [toastStatus, setToastStatus] = useState(null);
    const {state: {shouldOpenAddClientModal, userDataObject, listOfUsersClients, accountantObject}, dispatch} = useContext(AppContext);
    const [clientUidToChange, setClientUidToChange] = useState(null);
    const [shouldOpenUpdateAmountModal, setShouldOpenUpdateAmountModal] = useState(false);
    const [amountPaid, setAmountPaid] = useState(0)
    const [openSummaryModal, setOpenSummaryModal] = useState(false)
    const user = firebaseApp.auth().currentUser
    const history = useHistory()
    const classes = useStyles();


    useEffect(() => {
        // set initial amount of money paid to contractor when modal opens
        if (listOfUsersClients && clientUidToChange) {
            const initialAmount = listOfUsersClients.filter(user => user.uid === clientUidToChange)[0].paid
            setAmountPaid(initialAmount)
        }
    }, [listOfUsersClients, clientUidToChange])

    useEffect(() => {
        async function getPublicData() {
            try {
                const accountantData = await getAccountantPublicData(await firebase.auth().currentUser.getIdToken())
                dispatch({accountantData: accountantData})
            } catch (e) {
                showToast("error", "Something went wrong, please refresh!")
            }
        }

        if (user) {
            getPublicData()
        }
    }, [user])

    function getColumnByType(columnType) {
        const readyToFile = []
        const notReadyToFile = []
        const filed = []

        listOfUsersClients && listOfUsersClients.forEach(client => {
            if (isW9Complete(client) && client.filed === enums.NO) {
                readyToFile.push(client)
            } else if (!isW9Complete(client) && client.filed === enums.NO) {
                notReadyToFile.push(client)
            } else if (isW9Complete(client) && client.filed === enums.YES) {
                filed.push(client)
            } else {
                console.log("error")
            }
        })
        switch (columnType) {
            case 'NOT_READY':
                return notReadyToFile
            case 'READY':
                return readyToFile
            case 'FILED':
                return filed
        }
    }

    async function archiveClient(userToArchiveUid) {
        try {
            await archiveClientInDb(await firebase.auth().currentUser.getIdToken(), userToArchiveUid)
            showToast('success', 'User archived!')
        } catch (e) {
            showToast('error', 'Something went wrong, please try again!')
        }
    }

    async function handleSubmitChangeAmount() {
        const updatedContractorObject = listOfUsersClients.filter(user => user.uid === clientUidToChange)[0]
        updatedContractorObject.paid = amountPaid
        try {
            await updateUserInDb(dispatch, updatedContractorObject, await firebaseApp.auth().currentUser.getIdToken())
            dispatch({showLoadingGif: false})
            setShouldOpenUpdateAmountModal(false)
            showToast('success', 'Amount updated!')
        } catch (e) {
            showToast("error", "Something went wrong, please try again!")
        }
    }

    async function handleAddContractor(newContractorObject) {
        newContractorObject.accountantClientUid = firebaseApp.auth().currentUser.uid
        try {
            await addContractorToDb(newContractorObject, userDataObject, listOfUsersClients, dispatch, showToast)
            showToast("success", "Contractor Added!")
        } catch (e) {
            console.log(e)
            showToast("error", "Something went wrong, please try again! (Perhaps you used that email already?)")
        }
    }

    function showToast(toastType, toastMessage) {
        setToastStatus({
            toastType: toastType,
            toastMessage: toastMessage,
            date: Date()
        })
    }

    if (!userDataObject) {
        return <LoadingScreen/>
    }

    if (!userDataObject.hasConfirmedInformation) {
        return <FirstTimeLogIn showToast={showToast}/>
    }

    return (
        <div>
            <Menu listOfItemsToRender={<AccountantsClientMenuListItems/>}/>
            <AppBarSpacer/>
            <Grid container
                  direction={'column'} alignContent={'center'} spacing={2}
                  style={{marginTop: 30}}
            >
                {userDataObject
                && userDataObject.contractors.length === 0
                &&
                <Grid item style={{textAlign: "center"}}>
                    <Typography variant={"h2"}>
                        Click plus to add your first contractor</Typography>
                </Grid>}

                <Grid item xs={12}>
                    {userDataObject
                    && userDataObject.contractors.length > 0
                    && <Button variant={"contained"} color={"primary"} className={classes.button}
                               onClick={() => {
                                   if (doesUserHaveAllFieldsNeededToFile(userDataObject)) {
                                       setOpenSummaryModal(true)
                                   } else {
                                       showToast('error', 'Incomplete profile! Redirecting you to the profile page')
                                       const pathname =
                                           setTimeout(() => history.push(
                                               {
                                                   pathname: '/' + user.uid + '/profile',
                                                   state: {requiredFields: true}
                                               }), 2000)
                                   }
                               }}>
                        Request 1099 filings from your accountant</Button>}
                </Grid>

                <Grid item xs={12}>
                    {userDataObject
                    && userDataObject.contractors.length > 0
                    && listOfUsersClients
                    && <TableOverviewPerReadinessToFile
                        setOpenSummaryModal={setOpenSummaryModal}
                        accountTypeViewing={enums.ACCOUNTANTS_CLIENT}
                        setClientUidToChange={setClientUidToChange}
                        setShouldOpenUpdateAmountModal={setShouldOpenUpdateAmountModal}
                        sendReminderEmail={sendReminderEmail}
                        showToast={showToast}
                        filed={getColumnByType("FILED")}
                        notReadyToFile={getColumnByType("NOT_READY")}
                        readyToFile={getColumnByType("READY")}
                        archiveClient={archiveClient}
                    />}
                </Grid>


            </Grid>

            <Fab className={userDataObject && userDataObject.contractors.length === 0 ? classes.pulse : classes.fab}
                 color="primary" aria-label="add" onClick={() => {dispatch({shouldOpenAddClientModal: true})}}>
                <AddIcon/>
            </Fab>


            {shouldOpenAddClientModal &&
            <AddClientModal
                showToast={showToast}
                contextTriggerFieldToUpdate={{addContractor: true}}
                title={"Add Contractor"}
                clientTypeToAdd={enums.CONTRACTOR}
                onSubmitHandlerFunction={handleAddContractor}
                onCancelHandlerFunction={() => dispatch({shouldOpenAddClientModal: false})}
            />}

            {shouldOpenUpdateAmountModal &&
            clientUidToChange &&
            <UpdateSingleNumericFieldModal
                title={"Update amount paid to contractor"}
                shouldOpenUpdateFieldModal={shouldOpenUpdateAmountModal}
                setShouldOpenUpdateFieldModal={setShouldOpenUpdateAmountModal}
                handleSubmit={handleSubmitChangeAmount}
                initialValue={amountPaid}
                label={"amount paid"}
                setInitialValue={setAmountPaid}
                errorText={"Input numeric value"}
                typeToBeInputted={'number'}
            />}

            {openSummaryModal &&
            <SummaryModal showToast={showToast}
                          contractorsReadyToFile={getColumnByType("READY")}
                          setOpenModal={setOpenSummaryModal}
                          openModal={openSummaryModal}
                          title={'Your Accountant will receive your request to file for\n' +
                          'the following 1099\'s'}
                          accountTypeViewing={enums.ACCOUNTANTS_CLIENT}
                          displayPaymentDetails={false}
                          showPayments={false}

            />}

            {toastStatus ? <Toast key={toastStatus.date} toastStatus={toastStatus}/> : null}
        </div>
    );
};

export default AccountantsClientDashboard;

const useStyles = makeStyles(theme => ({
    fab: {
        position: "fixed",
        top: 80,
        right: 10,
    },

    pulse: {
        position: "fixed",
        top: 80,
        right: 10,
        boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
        transform: "scale(1)",
        animation: "$pulse 2s infinite"
    },
    "@keyframes pulse": {
        "0%": {
            transform: "scale(0.95)",
            boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)"
        },
        "70%": {
            transform: "scale(1)",
            boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)"
        },
        "100%": {
            transform: "scale(0.95)",
            boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)"
        }
    },

    button: {
        borderRadius: 30
    }
}))
