import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import UserInformationForm from "../../utils/modals/UserInformationForm";
import {enums} from "../../utils/enums";
import {updateUserInDb} from "../../utils/sharedFunctions";
import Menu from "../Menu/Menu";
import AppBarSpacer from "../../utils/sharedComponents/AppBarSpacer";
import {AppContext} from "../../context/AppContext";
import Button from "@material-ui/core/Button";
import {useParams} from "react-router";
import axios from "axios";
import {serverUrl} from "../../config";
import Toast from "../../utils/sharedComponents/Toast";
import ThankYouPage from "./ThankYouPage";
import LoadingScreen from "../../utils/sharedComponents/LoadingScreen";
import {load} from "dotenv";

const ContractorFormView = () => {
    const classes = useStyles();
    const [contractorObject, setContractorObject] = useState(false)
    const {dispatch} = useContext(AppContext);
    const [toastStatus, setToastStatus] = useState(null);
    const [displayThankYou, setDisplayThankYou] = useState(false);
    const [loading, setLoading] = useState(false);
    let {uid: contractorUid} = useParams();

    useEffect(() => {
        function getContractorData() {
            dispatch({loadingBarProgress: 40})
            axios({
                method: "POST",
                url: serverUrl + '/getContractorData',
                data: {
                    uid: contractorUid,
                },
            }).then(response => {
                dispatch({loadingBarProgress: 100})
                setContractorObject(response.data.contractorObject)
                setLoading(false)
                showToast('success', "Account information fetched!")
            }).catch(function (error) {
                if (error.response) {
                    setLoading(false)
                    showToast('error', "Something went wrong, please refresh the page!")
                }
            });
        }

        getContractorData()
    }, [])

    //TODO: look up data from db for this contractor that was already inputed
    function showToast(toastType, toastMessage) {
        setToastStatus({
            toastType: toastType,
            toastMessage: toastMessage,
            date: Date()
        })
    }

    async function handleSubmitUpdateContractor() {
        try {
            setLoading(true)
            await updateUserInDb(dispatch, contractorObject)
            dispatch({showLoadingGif: false})
            showToast('success', 'Information updated!')
            setDisplayThankYou(true)
        } catch (e) {
            setLoading(false)
            showToast("error", "Something went wrong, please try again!")
        }
    }

    // if(loading){
    //     return <LoadingScreen/>
    // }

    if (!contractorObject) {
        return <LoadingScreen/>
    }

    if (displayThankYou) {
        return <ThankYouPage/>
    }

    return (
        <div>
            <Menu doNotRenderOptions={true}/>

            <AppBarSpacer/>

            {!loading
            && <Grid container direction={"column"} alignContent={"center"} alignItems={"center"} justify={"center"}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant={"h2"}>We appreciate you filling out your W9 information! </Typography>
                    <Typography variant={"h2"}>It was a pleasure working with you </Typography>
                </Grid>

                <Grid item xs={4} style={{marginTop: 20}}>
                    <UserInformationForm
                        clientTypeToAdd={enums.CONTRACTOR}
                        setClientObject={setContractorObject}
                        existingUserDataObject={contractorObject}
                    />
                </Grid>

                <Grid item style={{marginTop: 20}}>
                    <Button color={"primary"} variant={"contained"}
                            onClick={() => {
                                setLoading(true)
                                handleSubmitUpdateContractor()
                            }}> Submit </Button>
                </Grid>
            </Grid>}
            {loading &&
            <LoadingScreen/>
            }

            {toastStatus ? <Toast key={toastStatus.date} toastStatus={toastStatus}/> : null}
        </div>
    );
};

export default ContractorFormView;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

