import React, {useContext, useEffect, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import UserInformationForm from "./UserInformationForm";
import {AppContext} from "../../context/AppContext";
import PropTypes from "prop-types";
import loadingGif from '../../assets/loadingGreen.gif'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const AddClientModal = ({
                            showToast,
                            clientTypeToAdd,
                            title,
                            onSubmitHandlerFunction,
                            onCancelHandlerFunction,
                            clientToEdit,
                            warningMessage
                        }) => {
    const {state: {shouldOpenAddClientModal, canSubmitAddClientForm, showLoadingGif}, dispatch} = useContext(AppContext);
    const [clientObject, setClientObject] = useState(null);


    return (
        <div>
            <Dialog
                fullWidth
                open={shouldOpenAddClientModal}
                onClose={() => onCancelHandlerFunction()}
                aria-labelledby="form-dialog-title"
                onBackdropClick={() => onCancelHandlerFunction()}
            >
                <section>
                    <DialogTitle id="form-dialog-title"><strong>{title}</strong></DialogTitle>
                    {warningMessage &&
                    <Typography variant={"body1"} style={{marginLeft: 20, color: 'red'}}>{warningMessage}</Typography>}
                    <DialogContent style={{minHeight: '90vh'}}>
                        <DialogContentText>
                            * required fields
                        </DialogContentText>
                        {!showLoadingGif
                        &&
                        <UserInformationForm showToast={showToast}
                                             clientTypeToAdd={clientTypeToAdd}
                                             setClientObject={setClientObject}
                                             existingUserDataObject={clientToEdit}
                        />
                        }
                        {showLoadingGif
                        && <Grid container direction={"column"} justify={"center"} alignItems={"center"}
                                 alignContent={"center"} style={{textAlign: "center", height: '100%'}}>
                            <Grid item>
                                <img src={loadingGif}
                                     alt={'loading_gif'}
                                     style={{width: 200, display: 'block', marginTop: 50}}
                                />
                            </Grid>
                        </Grid>}
                    </DialogContent>
                    <DialogActions style={{position: 'sticky', bottom: 10, right: 5}}>
                        <Button
                            variant={"outlined"}
                            onClick={() => onCancelHandlerFunction()} color="secondary">
                            Cancel
                        </Button>

                        <Button
                            disabled={!canSubmitAddClientForm}
                            onClick={() => {
                                onSubmitHandlerFunction(clientObject)
                            }}
                            variant={'contained'}
                            color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </section>
            </Dialog>
        </div>

    );
};

export default AddClientModal;

AddClientModal.propTypes = {
    showToast: PropTypes.func.isRequired,
    onSubmitHandlerFunction: PropTypes.func.isRequired,
    onCancelHandlerFunction: PropTypes.func.isRequired,
    clientTypeToAdd: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,

};
