import React, {useContext, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Menu from "../Menu/Menu";
import {makeStyles} from "@material-ui/core/styles";
import firebase from "firebase";
import {useHistory} from "react-router";
import Toast from "../../utils/sharedComponents/Toast";
import {enums} from "../../utils/enums";
import axios from "axios";
import {serverUrl} from "../../config";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import loadingGif from '../../assets/loadingGreen.gif'
import firebaseApp from "../../Firebase/firebaseApp";
import {Check} from "@material-ui/icons";

const AccountantSignUp = () => {
    const [toastStatus, setToastStatus] = useState(null);
    const [showLoadingGif, setShowLoadingGif] = useState(false)
    const [companyName, setCompanyName] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [logo, setLogo] = useState(null);

    const classes = useStyles();
    const history = useHistory()
    let user = firebase.auth().currentUser

    function showToast(toastType, toastMessage) {
        setToastStatus({
            toastType: toastType,
            toastMessage: toastMessage,
            date: Date()
        })
    }

    async function uploadImageAndGetDownloadLink() {
        if (!logo) {
            return ""
        }
        try {
            let storageRef = firebaseApp.storage().ref();
            const metadata = {
                contentType: 'image/jpeg',
            };

            let uploadTask = await storageRef.child('logos/' + companyName).put(logo, metadata);
            let downloadUrl = await uploadTask.ref.getDownloadURL()
            return downloadUrl

        } catch (e) {
            showToast("error", 'Failed to  upload, please try again')
        }
    }

    function createAccountant(userDataObject) {
        setShowLoadingGif(true)
        axios({
            method: "POST",
            url: serverUrl + '/createAccountantUser',
            data: userDataObject,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            if (response.status === 200) {
                //TODO: TOAST SUCCESS
                setShowLoadingGif(false)
                history.push('dashboard')
                showToast("success", "Account created!")
            }
        }).catch(function (error) {
            if (error.response) {
                setShowLoadingGif(false)
                showToast("error", "Something went wrong please refresh and try again")
            }
        });
    }

    async function handleCreateAccountant() {

        let userDataObject = {
            email: user.email,
            firstName: companyName,
            // companyAddress: companyAddress,
            listOfUsersClients: [],
            logo: await uploadImageAndGetDownloadLink(),
            uid: user.uid,
            accountType: enums.ACCOUNTANT,
            authToken: await user.getIdToken(),
            personToPay: enums.ACCOUNTANT_PAYS
        }
        createAccountant(userDataObject)
    }

    return (
        <div>
            <Menu doNotRenderOptions={true}/>
            <div className={classes.appBarSpacer}/>

            <Grid container className={classes.container} direction={"column"} justify={'center'} alignItems={'center'}
                  alignContent={"center"}>
                <Grid item>
                    <Typography variant={"h2"}>Welcome</Typography>
                </Grid>

                <Grid item>
                    <Typography variant={'h4'}>To create your dashboard let's get started with your
                        information</Typography>
                </Grid>

                {!showLoadingGif &&
                <Grid item className={classes.form}>
                    <form>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="clientsName"
                            label="Company name"
                            type="text"
                            fullWidth
                            required
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                        {/*<TextField*/}
                        {/*    margin="dense"*/}
                        {/*    id="clientsLastName"*/}
                        {/*    label="Company Address"*/}
                        {/*    type="text"*/}
                        {/*    fullWidth*/}
                        {/*    required*/}
                        {/*    value={companyAddress}*/}
                        {/*    onChange={(e) => setCompanyAddress(e.target.value)}*/}
                        {/*/>*/}

                        <Typography style={{fontSize: 12}} variant={"body1"}>* required fields</Typography>
                    </form>

                    <label htmlFor={'upload-button'}>
                        {!logo && <div className={classes.chooseFile}>
                            <AddAPhotoIcon style={{marginRight: 10}}/> <Typography>Add company logo</Typography>
                        </div>}
                        { logo && <div className={classes.chooseFile}>
                            <Check style={logo ? {marginRight: 10, color: 'green'} : {marginRight: 10}}/>
                            Image Chosen
                        </div>}
                    </label>
                    <input type={'file'} id="upload-button" style={{display: 'none'}}
                           onChange={(e) => setLogo(e.target.files[0])}/>
                </Grid>}

                {!showLoadingGif &&
                <Grid item>
                    <Button disabled={!companyName} className={classes.btn} variant={'contained'}
                            onClick={async () => await handleCreateAccountant()}>Go To Dashboard</Button>
                </Grid>}

                {showLoadingGif &&
                <Grid item>
                    <img src={loadingGif}
                         alt={'loading_gif'}
                         style={{maxWidth: 450}}
                    />
                </Grid>
                }

            </Grid>
            {toastStatus ? <Toast key={toastStatus.date} toastStatus={toastStatus}/> : null}

        </div>
    );
};

export default AccountantSignUp;

const useStyles = makeStyles(theme => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        textAlign: "center"
    },
    btn: {
        minWidth: '60%',
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        margin: 10,
        borderRadius: 30,
        marginTop: '20vh'
    },
    chooseFile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        marginTop: 20,
        padding: 5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 30,
        color: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
    },
    form: {
        marginTop: '10vh'
    }
}))
