import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Toast from "../../utils/sharedComponents/Toast";
import MenuHomePage from "./MenuHomePage";
import {makeStyles} from "@material-ui/core/styles";
import HowItWorksCard from "./HowItWorksCard";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import SignUpForm from "./SignUpForm";
import background from '../../assets/background.png'
import Container from "@material-ui/core/Container";
import ReactPixel from "react-facebook-pixel";
import Paper from "@material-ui/core/Paper";
import ReactGA from 'react-ga4'

const HomePage = () => {

    const [toastStatus, setToastStatus] = useState(null);
    const classes = useStyles();
    ReactPixel.pageView();

    function showToast(toastType, toastMessage) {
        setToastStatus({
            toastType: toastType,
            toastMessage: toastMessage,
            date: Date()
        })
    }
    ReactGA.send("pageview");

    return (
        <div>
            <MenuHomePage/>
            <Container style={{backgroundImage: `url(${background})`, width: '100vw', minWidth: '100%'}}>
                <Container maxWidth={"lg"}>
                    <Grid container direction={"row"} justify={'center'} alignItems={'center'} alignContent={"center"}
                          className={classes.getStartedContainer} style={{backgroundImage: `url(${background})`}}>
                        <Grid className={classes.gridItem} item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography className={classes.text} variant={"h1"}>Your 1099 Filings should be hassle free
                                and
                                now
                                they are</Typography>
                            <Typography className={classes.text} variant={"h4"}>eFile Buddy is an online tax filing
                                system
                                built to make
                                filing your early 1099 forms fast simple and safe</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <SignUpForm showToast={showToast}/>
                        </Grid>

                    </Grid>
                </Container>
            </Container>

            <Grid container className={classes.howItWorksContainer}>
                <Grid item xs={12}>
                    <Grid container direction={"column"} alignContent={"center"} alignItems={"center"}
                          style={{marginTop: 60}}>
                        <Grid item> <Typography variant={"h4"}>How it Works</Typography></Grid>

                        <Grid item> <Typography variant={"h2"} className={classes.titleText}>You The
                            Accountant</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <HowItWorksCard title={'Set up your dashboard'} imgSrc={'images/accountant_dashboard.svg'}
                                    description={"Your Accountant dashboard needs only your client’s name and email address to invite them to a personalized portal branded to your Accounting Firm."}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <HowItWorksCard title={'Get Notified'} imgSrc={'images/accountant_get notified.svg'}
                                    description={"Your client enters the name and email for each of their contractors. The contractor completes their W9 right in the smart email they receive. The client can also manually enter the contractors W9 information themselves. When your client is ready to file, eFileBuddy notifies you."}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <HowItWorksCard title={'Get Approved & Paid'} imgSrc={'images/accountant_getpaid.svg'}
                                    description={"You approve the 1099’s, and your client is notified their forms are prepared. Your client then pays whatever fees you set, which releases the 1099’s for download."}/>
                </Grid>
            </Grid>

            <Grid container style={{marginTop: 30}}>
                <Grid item xs={12}>
                    <Grid container direction={"column"} alignContent={"center"} alignItems={"center"}>
                        <Grid item> <Typography variant={"h2"} className={classes.titleText}>Your Valued
                            Clients</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <HowItWorksCard title={'Intuitive System'} imgSrc={'images/likebig.svg'}
                                    description={"With just the name and email of their 1099 contractors, eFileBuddy requests the recipient W9 info."}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <HowItWorksCard title={'Manage Contractor Easily'} imgSrc={'images/manager.svg'}
                                    description={"Your client can manage all their contractors from their personalized Accountant branded portal."}/>
                </Grid>
            </Grid>

            <Grid container className={classes.howItWorksContainer} style={{paddingTop: 40, paddingBottom: 40}}>
                <Grid item xs={12}>
                    <Grid container direction={"column"} alignContent={"center"} alignItems={"center"}>

                        <Grid item> <Typography variant={"h2"} className={classes.titleText}>The 1099 recipient
                            Contractor</Typography></Grid>
                        <Grid item> <Typography variant={"h4"}>Your valued clients may love running their business.
                            But
                            they dread the paperwork</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop: 30}}>
                    <Grid container direction={"column"} alignContent={"center"}>
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItem>
                                <ListItemIcon>
                                    <DoneOutlineIcon color={'primary'}/>
                                </ListItemIcon>
                                <ListItemText primary="The contractor gets a W9 embedded in a smart email "/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneOutlineIcon color={'primary'}/>
                                </ListItemIcon>
                                <ListItemText
                                    primary="They simply fill it out, and the data is instantly integrated in the eFileBuddy system "/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneOutlineIcon color={'primary'}/>
                                </ListItemIcon>
                                <ListItemText
                                    primary="They get their 1099 via email in a PDF encrypted with their EIN as the password"/>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>

            </Grid>

            <Grid container alignContent={"center"} justify={"center"} alignItems={"center"}
                  className={classes.footer}>

                <Grid item xs={6}>
                    <div>
                        <Typography variant={"h4"}>Headquarters<br/><br/></Typography>
                        <Typography variant={"body1"}>8 The Green, Ste A<br/>Dover, DE 19901 <br/> 888-969-3453 <br/> (888-WOW-FILE)</Typography>
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <SignUpForm showToast={showToast} color={'white'}/>
                </Grid>
            </Grid>

            {toastStatus ? <Toast key={toastStatus.date} toastStatus={toastStatus}/> : null}

        </div>
    );
};

export default HomePage;

const useStyles = makeStyles((theme) => ({
    footer: {
        height: 500,
        background: '#292D2C 0% 0% no-repeat padding-box',
        opacity: 1,
        color: 'white',
        textAlign: 'center'
    },
    img: {
        width: '40%'
    },
    gridItem: {
        // margin: theme.spacing(2)
    },
    getStartedContainer: {
        // backgroundColor: theme.palette.primary.light,
        padding: theme.spacing(2),
        minHeight: '80vh'
    },
    text: {
        color: theme.palette.neutral.main,
        margin: theme.spacing(3)
    },
    getStartedButton: {
        width: '60%',
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        margin: 10,
        borderRadius: 30
    },

    getStartedBox: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.neutral.main,
        maxWidth: '80%',
        minHeight: '40vh',
        borderRadius: 10,
        padding: theme.spacing(3),
        marginBottom: 10,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',

    },
    titleText: {
        color: theme.palette.primary.light,
        margin: theme.spacing(4),
        marginBottom: 50,
        textAlign: 'center'
    },
    howItWorksContainer: {
        marginTop: theme.spacing(0),
        backgroundColor: theme.palette.neutral.gray
    },


}))

