import React from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import PricingPackages from "../Billing/PricingPackages";
import MenuHomePage from "../HomePage/MenuHomePage";
import {useTheme} from "@material-ui/core";

const Pricing = () => {
    const classes = useStyles();
    const theme = useTheme()
    return (
        <div style={{backgroundColor: theme.palette.neutral.gray}}>
            <MenuHomePage/>

            <Grid container direction={"column"} alignContent={"center"} alignItems={"center"} justify={"center"}
                  style={{marginTop: 10}} spacing={2}>
                <Grid item>
                    <Typography variant={"h1"}><strong>Pricing</strong></Typography>
                </Grid>

                <Grid item>
                    <Typography variant={"h4"}><strong>We are excited to offer you our beta launch pricing
                        special!</strong></Typography>
                </Grid>
                <Grid item>
                </Grid>

                <Grid item style={{ textAlign: 'right'}}>
                    <Typography variant={"body1"} style={{marginBottom: 15, marginTop: 20}}>
                        <strong>For limited time get GOLD level for $99 (Bronze
                            Special) Subscription!</strong></Typography>
                    <PricingPackages isPublic={true}/>
                </Grid>
            </Grid>
        </div>
    );
};

export default Pricing;

const useStyles = makeStyles((theme) => ({}));

