import React, {useContext} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {AppContext} from "../../../context/AppContext";
import Menu from "../../Menu/Menu";
import AppBarSpacer from "../../../utils/sharedComponents/AppBarSpacer";
import {makeStyles} from "@material-ui/core/styles";

const Congratulations = () => {
    const {state: {listOfUsersClients}, dispatch} = useContext(AppContext);
    const classes = useStyles();

    return (
        <div>
            <Menu doNotRenderOptions={true}/>
            <AppBarSpacer/>
            <Grid container
                  justify={"center"} alignContent={"center"} alignItems={"center"} direction={"column"} spacing={4}
                  style={{textAlign: 'center', height: '100%', width: '100%'}}>
                <Grid item>
                    <Typography variant={"h2"}><strong>Congratulations</strong></Typography>
                </Grid>

                <Grid item>
                    <img style={{maxWidth: '20vw'}} alt={'confetti'}
                         src={'https://pngimg.com/uploads/confetti/confetti_PNG86957.png'}/>
                </Grid>

                <Grid item>
                    <Typography variant={"h4"}> Welcome to eFileBuddy</Typography>
                    <Typography variant={"h4"}> Please check your email for the link to activate full dashboard
                        functionality</Typography>
                </Grid>
                {/*TODO: should this be grayed out until they confirm email?*/}
                <Grid item>
                    <Button className={classes.continueButton} variant={"contained"} onClick={() => dispatch({continueToStep2: true})}>Continue
                        Setup</Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default Congratulations;
const useStyles = makeStyles((theme) => ({
    gridItem: {
        // margin: theme.spacing(2)
    },
    getStartedContainer: {
        backgroundColor: theme.palette.primary.light,
        padding: theme.spacing(2),
        minHeight: '80vh'
    },
    text: {
        color: theme.palette.neutral.main,
        margin: theme.spacing(3)
    },
    continueButton: {
        // width: '60%',
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        margin: 10,
        borderRadius: 30
    },}))
