import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import StripePremade from "../Payments/StripePremade";
import TextField from "@material-ui/core/TextField";
import {getFreeCredits} from "../../utils/sharedFunctions";
import * as firebase from "firebase";

const CheckoutModal = ({openModal, setOpenModal, showToast, packageToBuy, showPromoCode}) => {
    const classes = useStyles();
    const [numberOfCreditsToBuy, setNumberOfCreditsToBuy] = useState(10)
    const [promoCode, setPromoCode] = useState('');

    async function handleGetFreeCredits() {
        try {
            await getFreeCredits(await firebase.auth().currentUser.getIdToken(), promoCode)
            showToast('success', 'Payment Complete!')
            setOpenModal(false)
            window.location.reload()
        } catch (e) {
            console.log(e)
            showToast("error", 'Something went wrong, please try again!')
        }
    }

    return (
        <Dialog
            maxWidth={false}
            open={openModal}
            onClose={() => setOpenModal(false)}
            onBackdropClick={() => setOpenModal(false)}
            aria-labelledby="form-dialog-title">
            <section>
                <DialogTitle id="form-dialog-title">
                    <strong>
                        Checkout
                    </strong>
                </DialogTitle>
                <DialogContent>
                    <Grid container alignContent={'center'} alignItems={"center"} justify={"center"}
                          direction={"column"}
                          style={{marginTop: 10}} spacing={4}>

                        {!packageToBuy
                        && !showPromoCode
                        && <Grid item xs={12} style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            justifyItems: "flex-start",
                            width: '80%'
                        }}>
                            <TextField className={classes.field}
                                       fullWidth
                                       defaultValue={numberOfCreditsToBuy}
                                       onChange={(event) => setNumberOfCreditsToBuy(parseFloat(event.target.value))}
                                       id="creditsToBuy" label="Number of credits to buy" type="integer"
                                       variant="standard"/>
                        </Grid>}

                        {!packageToBuy
                        && showPromoCode
                        && <section style={{width: 400, height: 400}}>
                            <Grid item xs={12} style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                justifyItems: "flex-start",
                                width: '80%',
                                margin: 20
                            }}>
                                <TextField className={classes.field}
                                           fullWidth
                                           defaultValue={promoCode}
                                           onChange={(event) => setPromoCode(event.target.value)}
                                           id="promoCode" label="Enter Promo code" type="integer"
                                           variant="standard"/>
                            </Grid>
                            <Grid item xs={12} style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                justifyItems: "flex-start",
                                width: '80%',
                                margin: 20
                            }}>
                                <Button onClick={() => handleGetFreeCredits()} color={"primary"} variant={"contained"}>Get
                                    Free Credits</Button>
                            </Grid>
                        </section>}


                        {!showPromoCode &&
                        <Grid item xs={12} style={{marginTop: 30}}>
                            {
                                <StripePremade showToast={showToast}
                                               setOpenModal={setOpenModal}
                                               packageToBuy={packageToBuy}
                                               numberOfCreditsToBuy={numberOfCreditsToBuy}
                                />
                            }
                            {/*<Button variant={"contained"} color={"primary"} onClick={() => setShowBilling(true)}>Approve</Button>*/}
                        </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </section>


        </Dialog>
    );
};

export default CheckoutModal;

const useStyles = makeStyles((theme) => ({}));

