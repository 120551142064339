import React, {useContext, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import MenuHomePage from "../HomePage/MenuHomePage";
import {useTheme} from "@material-ui/core";
import axios from "axios";
import Toast from "../../utils/sharedComponents/Toast";

const Contact = () => {
    const classes = useStyles();
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const theme = useTheme()
    const [toastStatus, setToastStatus] = useState(null);

    function showToast(toastType, toastMessage) {
        setToastStatus({
            toastType: toastType,
            toastMessage: toastMessage,
            date: Date()
        })
    }

    function sendToHubspot() {
        const data = JSON.stringify({
            "submittedAt": Date.now(),
            "fields": [
                {
                    "objectTypeId": "0-1",
                    "name": "email",
                    "value": email
                },
                {
                    "objectTypeId": "0-1",
                    "name": "firstname",
                    "value": name
                },
                {
                    "objectTypeId": "0-1",
                    "name": "message",
                    "value": "message"
                }, {
                    "objectTypeId": "0-1",
                    "name": "message",
                    "value": message
                }]
        });

        const config = {
            method: 'post',
            url: 'https://api.hsforms.com/submissions/v3/integration/submit/20778598/d8ae85cb-864b-47ae-b789-aee750968fa5',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                showToast("success", "Form successfully submitted")
            })
            .catch(function (error) {
                // console.log(error);
                showToast("error", "Something went wrong, please try again!")
            });
    }

    return (
        <div style={{backgroundColor: theme.palette.neutral.gray, height: '100vh'}}>
            <MenuHomePage/>
            <Grid container direction={"row"} alignContent={"center"} alignItems={"center"} justify={"center"}
                  style={{marginTop: 60}}>

                <Grid item style={{margin: 30}} xs={12} md={5}>
                    <Grid container justify={"center"} alignContent={"center"} alignItems={"center"}
                          component={Paper} style={{width: '100%', minHeight: '55vh'}}>
                        <div style={{
                            padding: 20,
                            margin: 20,

                        }}>
                            <Typography variant={"h2"} style={{marginBottom: 30}}>Contact us</Typography>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Name"
                                type="text"
                                placeholder={'Jane Doe'}
                                fullWidth
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                id="clientsLastName"
                                label="Email"
                                placeholder={'email@example.com'}
                                type="text"
                                fullWidth
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                multiline={10}
                                id="clientsLastName"
                                label="Message"
                                placeholder={"Hi, I'd like to collaborate? Let me know what you think! \nBest, Jane"}
                                type="text"
                                fullWidth
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <Button variant={"contained"} color={"primary"} onClick={() => {
                                sendToHubspot()
                            }}
                                    style={{marginTop: 20}}>Send</Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {toastStatus ? <Toast key={toastStatus.date} toastStatus={toastStatus}/> : null}

        </div>

    );
};

export default Contact;

const useStyles = makeStyles((theme) => ({}));

