import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useHistory, useParams} from "react-router";
import Menu from "../Menu/Menu";
import firebaseApp from "../../Firebase/firebaseApp";
import GeneratedForm from "./Form1099Generated/generatedForm";

const Review1099 = () => {
    const classes = useStyles();
    const history = useHistory()
    useEffect(() => {
        const loginFunction = async () => {
            firebaseApp.auth().onAuthStateChanged(function (user) {
                if (!user) {
                    history.push('/login')
                }
            });
        };
        loginFunction();
    }, [])

    // useProtectRoute()
    let {uid} = useParams();


    return (
        <div>
            <Menu/>
            <Grid container style={{marginTop: 60}}>
                <Grid item>
                    <GeneratedForm/>
                </Grid>
            </Grid>
        </div>
    );
};

export default Review1099;

const useStyles = makeStyles((theme) => ({}));

