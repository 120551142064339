import axios from "axios";
import {serverUrl} from "../config";
import {enums} from "./enums";
import firebaseApp from "../Firebase/firebaseApp";
import PropTypes from 'prop-types';
import firebase from "firebase";

export function getArrayOfObjectsWithRemovedItem(array, itemPropertyName, itemPropertyValueToDelete) {
    return array.filter(object => {
        return object[itemPropertyName] !== itemPropertyValueToDelete
    })
}

export function getListOfQuestionsForSurvey(questionObjects, surveyObject) {
    return questionObjects.filter(questionObject => {
        return questionObject.surveyUid === surveyObject.surveyUid
    })
}

export function getListOfAnswersForQuestion(answerObjects, questionObject) {
    return answerObjects.filter(answerObject => {
        return answerObject.questionUid === questionObject.questionUid
    })
}

export function sortArrayByNumericObjectProperty(array, objectProperty) {
    return array.sort((a, b) => {

        return (+a[objectProperty]) > (+b[objectProperty]) ? 1 : -1
    })
}

export function getObjectFromArrayThatMatchesValue(array, propertyToLookFor, valueToMatch) {
    return array.filter(object => {
        return object[propertyToLookFor] === valueToMatch
    })[0]
}

export function getIndexOfObjectInArray(arrayOfObjects, propertyToSearchFor, valueToMatch) {
    return arrayOfObjects.findIndex(x => x[propertyToSearchFor] === valueToMatch);
}

export function isAccountantsClientReadyToFile(requestedFilings) {
    if (requestedFilings) {
        return requestedFilings.length > 0
    } else {
        return false
    }
}

export function contractorReadyToFile() {

}

export function isNum(string) {
    return /^\d+$/.test(string)
}

export async function completePayorProfile(dispatch, updatedPayor, currentUsersIdToken) {
    return new Promise((res, rej) => {
        axios({
            method: 'POST',
            url: serverUrl + '/completePayorProfile',
            data: {
                userObject: updatedPayor,
                authToken: currentUsersIdToken
            },
            headers: {
                'crossDomain': true
            }
        }).then(response => {
            res(response.status);
        }).catch(function (error) {
            if (error.response) {
                rej('something went wrong')
            }
        })
    })
}

export async function request1099FromAccountant(dispatch, updatedPayor, currentUsersIdToken) {

    return new Promise((res, rej) => {
        axios({
            method: 'POST',
            url: serverUrl + '/notifyAccountantWhenPayorSubmittedAll1099Data',
            data: {
                userObject: updatedPayor,
                authToken: currentUsersIdToken
            },
            headers: {
                'crossDomain': true
            }
        }).then(response => {
            res(response.status);
        }).catch(error => {
            if (error.response) {
                rej('Something went wrong :(');
            }
        })
    })

}

export async function updateUserInDb(dispatch, updatedClient, currentUsersIdToken) {

    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: serverUrl + '/updateAccountantClientUser',
            data: {
                userObject: updatedClient,
                authToken: currentUsersIdToken,
            },
            headers: {
                'crossDomain': true,
            }
        }).then(response => {
            // dispatch({showLoadingGif: false})
            // dispatch({loadingBarProgress: 100})

            if (updatedClient.accountType === enums.ACCOUNTANTS_CLIENT) {
                // updatedClient.hasConfirmedInformation = true
                // dispatch({userDataObject: updatedClient})
            }
            //TODO: add else if to handle updating fields for accountant

            res(response.status)
        }).catch(function (error) {
            if (error.response) {
                // dispatch({loadingBarProgress: 100})
                // dispatch({showLoadingGif: false})
                // dispatch({updateAccountantsClient: false})

                rej("Something went wrong")
            }
        });

    })
}

export async function getAccountantPublicData(currentUsersIdToken) {

    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: serverUrl + '/getAccountantInformationPublic',
            data: {
                authToken: currentUsersIdToken,
            }
        }).then(response => {
            res(response.data)
        }).catch(function (error) {
            if (error.response) {
                rej("Something went wrong")
            }
        });

    })
}

export async function getMoreCredits(currentUsersIdToken, promoCode) {

    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: serverUrl + '/getMoreCredits',
            data: {
                authToken: currentUsersIdToken,
                promoCode: promoCode,
            },
            headers: {
                'crossDomain': true,
            }
        }).then(response => {
            res(response.data.creditsAdded)
        }).catch(function (error) {
            if (error.response) {
                rej("Something went wrong")
            }
        });

    })
}

export function archiveClientInDb(currentUsersIdToken, userToArchiveUid) {

    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: serverUrl + '/deleteUser',
            data: {
                currentUsersIdToken: currentUsersIdToken,
                userToArchiveUid: userToArchiveUid
            },
            headers: {
                'crossDomain': true,
            }
        }).then(response => {
            res(response.status)
        }).catch(function (error) {
            if (error.response) {
                rej("Something went wrong")
            }
        });

    })
}

export async function addAccountantsClientToDb(userDataObject, accountantsClientInformation, listOfUsersClients, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({showLoadingGif: true})
        axios({
            method: "POST",
            url: serverUrl + '/createAccountantsClientUser',
            data: {
                accountantsClientInformation: accountantsClientInformation,
                accountantInformation: userDataObject
            }
        }).then(response => {
            if (response.status === 200) {
                let newClient = accountantsClientInformation
                newClient.uid = response.data.accountantClientUid
                dispatch({listOfUsersClients: [...listOfUsersClients, newClient]})
                dispatch({loadingBarProgress: 100})
                dispatch({shouldOpenAddClientModal: false})
                dispatch({showLoadingGif: false})

                resolve(response.status)
            }
        }).catch(function (error) {
            if (error.response) {
                dispatch({loadingBarProgress: 100})
                dispatch({addAccountantsClient: false})
                dispatch({showLoadingGif: false})

                reject()
            }
        });
    })
}

export function addContractorToDb(newContractor, userDataObject, listOfUsersClients, dispatch, showToast) {
    return new Promise((resolve, reject) => {
        dispatch({loadingBarProgress: 40})
        dispatch({showLoadingGif: true})

        axios({
            method: "POST",
            url: serverUrl + '/createContractor',
            data: newContractor,
        }).then(response => {
            if (response.data.status === 500) {
                dispatch({loadingBarProgress: 100})
                dispatch({addContractor: false})
                showToast("error", response.data.message)
            } else if (response.status === 200) {
                newContractor.uid = response.data.contractorUid
                userDataObject.contractors = [...userDataObject.contractors, response.data.contractorUid]
                dispatch({listOfUsersClients: [...listOfUsersClients, newContractor]})
                dispatch({userDataObject: userDataObject})
                dispatch({loadingBarProgress: 100})
                dispatch({addContractor: false})
                dispatch({shouldOpenAddClientModal: false})
                dispatch({showLoadingGif: false})
                resolve(response.status)
            }
        }).catch(function (error) {
            if (error.response) {
                dispatch({loadingBarProgress: 100})
                dispatch({addContractor: false})
                dispatch({showLoadingGif: false})
                reject()
            }
        });
    })
}

export async function setUserDataGlobally(dispatch) {
    return new Promise(async (resolve, reject) => {
        axios({
            method: "POST",
            url: serverUrl + '/getUserData',
            data: {authToken: await firebaseApp.auth().currentUser.getIdToken()},
        }).then(response => {
            if (response.status === 200) {
                dispatch({userDataObject: response.data.userDataObject})
                dispatch({listOfUsersClients: response.data.listOfUsersClients})
                dispatch({loadingBarProgress: 100})
                resolve(response.data.userDataObject)
            }
        }).catch(function (error) {
            dispatch({loadingBarProgress: 100})
            reject()
        })
    });
}

export async function getAllContractorsForClient(clientUid) {
    return new Promise(async (resolve, reject) => {
        axios({
            method: "POST",
            url: serverUrl + '/getAllContractorsForClient',
            data: {clientUid: clientUid},
        }).then(response => {
            if (response.status === 200) {
                resolve(response.data.contractors)
            }
        }).catch(function (error) {
            reject()
        })
    });
}

export async function remindPayorToFile(userObject, accountTypeToRemind, dispatch, showToast) {
    remindPayorToFile.propTypes = {
        userObject: PropTypes.object.isRequired,
        accountTypeToRemind: PropTypes.string.isRequired,
        dispatch: PropTypes.func.isRequired
    }

    return new Promise((resolve, reject) => {
        dispatch({showLoadingGif: true});
        axios({
            method: 'POST',
            url: serverUrl + '/remindPayorToSubmit1099FromAccountant',
            data: {
                userObject
            }
        }).then(response => {
            dispatch({loadingBarProgress: 100})
            dispatch({showLoadingGif: false})
            showToast('success', "Reminder Sent")
            resolve(response.status)
        }).catch(error => {
            if (error.response) {
                dispatch({loadingBarProgress: 100})
                dispatch({showLoadingGif: false})
                reject()
            }
        });
    })
}

export async function sendReminderEmail(userObject, accountTypeToRemind, dispatch, showToast) {
    sendReminderEmail.propTypes = {
        userObject: PropTypes.object.isRequired,
        accountTypeToRemind: PropTypes.string.isRequired,
        dispatch: PropTypes.func.isRequired
    }

    function deduceRouteToRemind() {
        switch (accountTypeToRemind) {
            case enums.ACCOUNTANT:
                return '/remindAccountantToFile'
            case enums.CONTRACTOR:
                return '/remindContractor'
            case enums.ACCOUNTANTS_CLIENT:
                return '/remindAccountantsClientToFile'
        }
    }

    let routeToRemind = deduceRouteToRemind()

    return new Promise((resolve, reject) => {
        dispatch({showLoadingGif: true})
        axios({
            method: "POST",
            url: serverUrl + routeToRemind,
            data: {
                userObject: userObject,
            }
        }).then(response => {
            if (response.status === 200) {
                dispatch({loadingBarProgress: 100})
                dispatch({showLoadingGif: false})
                showToast('success', "Reminder Sent")
                resolve(response.status)
            }
        }).catch(function (error) {
            if (error.response) {
                dispatch({loadingBarProgress: 100})
                dispatch({showLoadingGif: false})
                reject()
            }
        });
    })
}

export async function inviteAccountantsClientToEFileBuddy(accountantsClientUid, currentUserToken) {

    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: serverUrl + '/remindClientToCompleteEfileBuddyClientAccount',
            data: {
                accountantsClientUid: accountantsClientUid,
                currentUserToken: currentUserToken
            }
        }).then(response => {
            if (response.status === 200) {
                resolve(response.status)
            }
        }).catch((error) => {
            if (error.response) {
                reject()
            }
        });
    })
}

export function isW9Complete(contractorObject) {
    return !(contractorObject.firstName === ''
        // || contractorObject.lastName === ''
        || contractorObject.email === ''
        || contractorObject.ein === ''
        || contractorObject.addressLine1 === ''
        || contractorObject.city === ''
        || contractorObject.state === ''
        || contractorObject.zipCode === ''
        || contractorObject.country === '')
}

export function getClientSecret(currentUsersIdToken, plan) {
    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: serverUrl + '/processPayment',
            data: {
                authToken: currentUsersIdToken,
                plan: plan
            },
        }).then(response => {
            res(response.data.clientSecret)
        }).catch(function (error) {
            if (error.response) {
                rej("Something went wrong")
            }
        });

    })
}

export function getClientSecretNumberOfTokens(currentUsersIdToken, credits) {
    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: serverUrl + '/processPaymentAddCredits',
            data: {
                authToken: currentUsersIdToken,
                credits: credits
            },
        }).then(response => {
            res(response.data.clientSecret)
        }).catch(function (error) {
            if (error.response) {
                rej("Something went wrong")
            }
        });

    })
}

export function getFreeCredits(currentUsersIdToken, promoCode) {
    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: serverUrl + '/getFreeCredits',
            data: {
                authToken: currentUsersIdToken,
                promoCode: promoCode
            },
        }).then(response => {
            res('success')
        }).catch(function (error) {
            if (error.response) {
                rej("Something went wrong")
            }
        });

    })
}

export function fileToIRS(currentUsersIdToken) {
    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: serverUrl + '/submitCompletedFilings',
            data: {
                authToken: currentUsersIdToken,
            },
        }).then(response => {
            res(response.data.clientSecret)
        }).catch(function (error) {
            if (error.response) {
                rej("Something went wrong")
            }
        });

    })
}

export function processSuccessfulPayment(paymentIntent, currentUsersIdToken, packageToBuy) {
    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: serverUrl + '/processPaymentSucceeded',
            data: {
                paymentIntent: paymentIntent,
                authToken: currentUsersIdToken,
                plan: packageToBuy
            },
        }).then(response => {
            res(response.data.clientSecret)
        }).catch(function (error) {
            if (error.response) {
                rej("Something went wrong")
            }
        });

    })
}

export function processSuccessfulPaymentAddCredits(paymentIntent, currentUsersIdToken, credits) {
    return new Promise((res, rej) => {
        axios({
            method: "POST",
            url: serverUrl + '/processPaymentSucceededAddCredits',
            data: {
                paymentIntent: paymentIntent,
                authToken: currentUsersIdToken,
                credits: credits
            },
        }).then(response => {
            res(response.data.clientSecret)
        }).catch(function (error) {
            if (error.response) {
                rej("Something went wrong")
            }
        });

    })
}

export function doesUserHaveAllFieldsNeededToFile(userDataObject) {
    if (!userDataObject) {
        return false
    }
    return userDataObject.firstName !== ''
        // && userDataObject.lastName !== ''
        && userDataObject.addressLine1 !== ''
        && userDataObject.city !== ''
        && userDataObject.state !== ''
        && userDataObject.zipCode !== ''
        && userDataObject.ein !== ''
        && userDataObject.phone !== ''
}

export function deduceCorrectDashboardToPushTo(accountType, userUid) {
    switch (accountType) {
        case "ACCOUNTANT":
            return '../dashboard'
        case "ACCOUNTANTS_CLIENT":
            return '../client/' + userUid
    }
}

export function deduceFiledStatus(clientRow) {
    switch (clientRow.accountType) {
        case enums.ACCOUNTANTS_CLIENT:
            return clientRow.filed
        case enums.CONTRACTOR:
            return clientRow.filed
    }
}

export function calculateNumberOfFiled(clientRow) {
    switch (clientRow.accountType) {
        case enums.ACCOUNTANTS_CLIENT:
            return clientRow.filed
        case enums.CONTRACTOR:
            return clientRow.filed
    }
}

export function getQueryStringParams(query) {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
}

export async function fetchAllDataForUser(user, dispatch, history, showToast) {
    axios({
        method: "POST",
        url: serverUrl + '/getAccountantInformation',
        data: {
            userUid: user.uid
        },
    }).then(response => {
        if (response.status === 200) {
            dispatch({loadingBarProgress: 80})
            dispatch({userDataObject: response.data.accountantInfo})
            dispatch({listOfUsersClients: [...response.data.clientsArray]})
            dispatch({loadingBarProgress: 100})
            showToast("success", "Data Fetched!");
        }
    }).catch(function (error) {
        if (error.response) {
            dispatch({loadingBarProgress: 100})
            showToast("error", "Something went wrong, please refresh the page!")
        } else if (error.response.status === 404) {
            showToast("error", "Not found")
            history.push('/login')
        } else {
            showToast("error", "Something went wrong, please refresh the page!")
        }

    });
}
