import React from "react";

function TestHomePage() {
  return (
      <div>
          <embed src={'https://brownwoodd.wixsite.com/my-site'} style={{width: '100%', height:'98vh'}}/>
      </div>
  );
}

export default TestHomePage;
