import React from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import UserInformationForm from "../../utils/modals/UserInformationForm";
import PropTypes from "prop-types";
import AccountantProfile from "./AccountantProfile";
import Button from "@material-ui/core/Button";

const AccountantsClientProfile = ({
                                      showToast,
                                      setClientObject,
                                      userDataObject,
                                      handleChangeInformation,
                                      shouldShowRequiredFields
                                  }) => {
    const classes = useStyles();

    return (
        <Grid container direction={"column"} alignContent={"center"} alignItems={"center"} justify={"center"}
              spacing={2}>
            <Grid item xs={12} md={6}>
                <UserInformationForm showToast={showToast}
                                     clientTypeToAdd={userDataObject.accountType}
                                     setClientObject={setClientObject}
                                     existingUserDataObject={userDataObject}
                                     shouldShowRequiredFields={shouldShowRequiredFields}
                />
            </Grid>

            <Grid item>
                <Button variant={"contained"} color={"primary"} onClick={() => handleChangeInformation()}>
                    Change information</Button>
            </Grid>
        </Grid>
    );
};

export default AccountantsClientProfile;

const useStyles = makeStyles((theme) => ({}));

AccountantsClientProfile.propTypes = {
    showToast: PropTypes.func.isRequired,
    setClientObject: PropTypes.func.isRequired,
    handleChangeInformation: PropTypes.func.isRequired,
    userDataObject: PropTypes.object.isRequired
}
