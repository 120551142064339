import React, { Component } from "react";
import styled, { css } from "styled-components";

function GeneratedForm(props) {
  return (
    <Rect>
      <LoremIpsum3ColumnRow>
        <LoremIpsum3Column>
          <LoremIpsum3>
            PAYER’S name, street address, city or town, state or province,
            country, ZIP or foreign postal code, and telephone no.
          </LoremIpsum3>
          <LoremIpsum6>1099-9909--990990</LoremIpsum6>
          <Rect5>
            <PayersTinStackStack>
              <PayersTinStack>
                <PayersTin>PAYER&#39;S TIN</PayersTin>
                <LoremIpsum>1099-9909--990990</LoremIpsum>
                <LoremIpsum1>1099-9909--990990</LoremIpsum1>
              </PayersTinStack>
              <RecepientsTin1>RECEPIENTS TIN</RecepientsTin1>
            </PayersTinStackStack>
          </Rect5>
        </LoremIpsum3Column>
        <Rect3Stack>
          <Rect3>
            <Year>YEAR</Year>
          </Rect3>
          <Rect4>
            <Form1099Nec>FORM 1099 NEC</Form1099Nec>
          </Rect4>
          <Rect6>
            <RecepientsTin2Stack>
              <RecepientsTin2>1 NON EMPLOYEE COMPENSATION</RecepientsTin2>
              <LoremIpsum2>$</LoremIpsum2>
            </RecepientsTin2Stack>
          </Rect6>
        </Rect3Stack>
      </LoremIpsum3ColumnRow>
      <Rect7StackStackRow>
        <Rect7StackStack>
          <Rect7Stack>
            <Rect7>
              <RecepientsNameStack>
                <RecepientsName>RECEPIENT&#39;S NAME</RecepientsName>
                <LoremIpsum5>1099-9909--990990</LoremIpsum5>
              </RecepientsNameStack>
            </Rect7>
            <Rect8>
              <PayersTin1>Street address (including apt. no.)</PayersTin1>
            </Rect8>
            <LoremIpsum4>1099-9909--990990</LoremIpsum4>
          </Rect7Stack>
          <Rect9>
            <PayersTin2>
              City or town, state or province, country, and ZIP or foreign
              postal code
            </PayersTin2>
            <PayersTin3>PAYER&#39;S TIN</PayersTin3>
          </Rect9>
        </Rect7StackStack>
        <IrsPopulatedFields>IRS POPULATED FIELDS</IrsPopulatedFields>
      </Rect7StackStackRow>
      <Rect10>
        <PayersTin4>Account number (see instructions)</PayersTin4>
        <PayersTin5>PAYER&#39;S TIN</PayersTin5>
      </Rect10>
    </Rect>
  );
}

const Rect = styled.div`
  display: flex;
  width: 1240px;
  height: 568px;
  border-width: 1px;
  border-color: #000000;
  flex-direction: column;
  margin-top: 75px;
  margin-left: 64px;
  border-style: solid;
`;

const LoremIpsum3 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 25px;
  width: 615px;
  margin-left: 9px;
`;

const LoremIpsum6 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 70px;
  width: 597px;
  margin-top: 19px;
  margin-left: 8px;
`;

const Rect5 = styled.div`
  width: 623px;
  height: 51px;
  border-width: 1px;
  border-color: #000000;
  flex-direction: column;
  display: flex;
  margin-top: 24px;
  border-style: solid;
`;

const PayersTin = styled.span`
  font-family: Roboto;
  top: 0px;
  left: 0px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 25px;
  width: 308px;
`;

const LoremIpsum = styled.span`
  font-family: Roboto;
  top: 21px;
  left: 0px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 25px;
  width: 308px;
`;

const LoremIpsum1 = styled.span`
  font-family: Roboto;
  top: 21px;
  left: 307px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 25px;
  width: 308px;
`;

const PayersTinStack = styled.div`
  top: 0px;
  left: 0px;
  width: 615px;
  height: 46px;
  position: absolute;
`;

const RecepientsTin1 = styled.span`
  font-family: Roboto;
  top: 0px;
  left: 309px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 25px;
  width: 308px;
`;

const PayersTinStackStack = styled.div`
  width: 617px;
  height: 46px;
  margin-top: 5px;
  margin-left: 4px;
  position: relative;
`;

const LoremIpsum3Column = styled.div`
  width: 624px;
  flex-direction: column;
  display: flex;
  margin-top: 7px;
`;

const Rect3 = styled.div`
  top: 0px;
  left: 0px;
  width: 308px;
  height: 146px;
  position: absolute;
  border-width: 1px;
  border-color: #000000;
  background-color: rgba(230, 230, 230,1);
  flex-direction: column;
  display: flex;
  border-style: solid;
`;

const Year = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 33px;
  width: 258px;
  text-align: center;
  font-size: 16px;
  margin-top: 56px;
  margin-left: 16px;
`;

const Rect4 = styled.div`
  top: 0px;
  left: 307px;
  width: 308px;
  height: 146px;
  position: absolute;
  border-width: 1px;
  border-color: #000000;
  flex-direction: column;
  display: flex;
  border-style: solid;
`;

const Form1099Nec = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 33px;
  width: 258px;
  text-align: center;
  font-size: 16px;
  margin-top: 56px;
  margin-left: 18px;
`;

const Rect6 = styled.div`
  top: 145px;
  left: 0px;
  width: 615px;
  height: 51px;
  position: absolute;
  border-width: 1px;
  border-color: #000000;
  flex-direction: column;
  display: flex;
  border-style: solid;
`;

const RecepientsTin2 = styled.span`
  font-family: Roboto;
  top: 0px;
  left: 0px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 25px;
  width: 308px;
`;

const LoremIpsum2 = styled.span`
  font-family: Roboto;
  top: 21px;
  left: 0px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 25px;
  width: 610px;
`;

const RecepientsTin2Stack = styled.div`
  width: 610px;
  height: 46px;
  margin-top: 5px;
  margin-left: 5px;
  position: relative;
`;

const Rect3Stack = styled.div`
  width: 615px;
  height: 196px;
  position: relative;
`;

const LoremIpsum3ColumnRow = styled.div`
  height: 196px;
  flex-direction: row;
  display: flex;
  margin-right: 1px;
`;

const Rect7 = styled.div`
  top: 0px;
  left: 0px;
  width: 623px;
  height: 146px;
  position: absolute;
  border-width: 1px;
  border-color: #000000;
  flex-direction: column;
  display: flex;
  border-style: solid;
`;

const RecepientsName = styled.span`
  font-family: Roboto;
  top: 0px;
  left: 0px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 25px;
  width: 308px;
`;

const LoremIpsum5 = styled.span`
  font-family: Roboto;
  top: 22px;
  left: 1px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 116px;
  width: 444px;
`;

const RecepientsNameStack = styled.div`
  width: 445px;
  height: 138px;
  margin-top: 5px;
  margin-left: 4px;
  position: relative;
`;

const Rect8 = styled.div`
  top: 145px;
  left: 0px;
  width: 623px;
  height: 78px;
  position: absolute;
  border-width: 1px;
  border-color: #000000;
  flex-direction: column;
  display: flex;
  border-style: solid;
`;

const PayersTin1 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 25px;
  width: 308px;
  margin-top: 5px;
  margin-left: 5px;
`;

const LoremIpsum4 = styled.span`
  font-family: Roboto;
  top: 171px;
  left: 5px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 53px;
  width: 614px;
`;

const Rect7Stack = styled.div`
  top: 0px;
  left: 0px;
  width: 623px;
  height: 224px;
  position: absolute;
`;

const Rect9 = styled.div`
  top: 223px;
  left: 0px;
  width: 623px;
  height: 74px;
  position: absolute;
  border-width: 1px;
  border-color: #000000;
  flex-direction: column;
  display: flex;
  border-style: solid;
`;

const PayersTin2 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 25px;
  width: 601px;
  margin-top: 5px;
  margin-left: 4px;
`;

const PayersTin3 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 34px;
  width: 615px;
  margin-top: 1px;
  margin-left: 4px;
`;

const Rect7StackStack = styled.div`
  width: 623px;
  height: 297px;
  position: relative;
`;

const IrsPopulatedFields = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 71px;
  width: 582px;
  font-size: 35px;
  text-align: center;
  margin-left: 17px;
  margin-top: 138px;
`;

const Rect7StackStackRow = styled.div`
  height: 297px;
  flex-direction: row;
  display: flex;
  margin-right: 18px;
`;

const Rect10 = styled.div`
  width: 623px;
  height: 74px;
  border-width: 1px;
  border-color: #000000;
  flex-direction: column;
  display: flex;
  margin-top: 1px;
  border-style: solid;
`;

const PayersTin4 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 18px;
  width: 615px;
  margin-top: 2px;
  margin-left: 4px;
`;

const PayersTin5 = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  height: 34px;
  width: 615px;
  margin-top: 7px;
  margin-left: 4px;
`;

export default GeneratedForm;
