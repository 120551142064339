import React, {useContext, useState} from 'react';
import {AppContext} from "../../../context/AppContext";
import PropTypes from "prop-types";
import {enums} from "../../../utils/enums";
import SummaryDialog from "./SummaryDialog";
import PaymentDialog from "./PaymentDialog";

const SummaryModal = ({
                          setOpenModal,
                          openModal,
                          showToast,
                          title,
                          contractorsReadyToFile,
                          accountTypeViewing,
                          showPayments
                      }
) => {

    const {state: {userDataObject, accountantData}, dispatch} = useContext(AppContext);
    const [showBilling, setShowBilling] = useState(false)


    function shouldShowPayment() {
        switch (userDataObject.accountType) {
            case enums.ACCOUNTANT:
                return enums.ACCOUNTANT_PAYS === userDataObject.personToPay
            case enums.ACCOUNTANTS_CLIENT:
                return enums.CLIENT_PAYS === accountantData.personToPay
        }
    }

    function areAnyOfTheContractorsPaid0() {
        if (!contractorsReadyToFile) {
            return false
        }
        if (contractorsReadyToFile.filter(contractor => {
            return parseFloat(contractor.paid) === 0
        }).length > 0) return true
    }

    return (
        <div>
            {!showBilling && <SummaryDialog shouldShowPayment={shouldShowPayment} title={title} dispatch={dispatch}
                                            setOpenModal={setOpenModal} setShowBilling={setShowBilling}
                                            userDataObject={userDataObject} openModal={openModal}
                                            areAnyOfTheContractorsPaid0={areAnyOfTheContractorsPaid0}
                                            showToast={showToast} contractorsReadyToFile={contractorsReadyToFile}
                                            accountTypeViewing={accountTypeViewing} showPayments={showPayments}/>}

            {showBilling && <PaymentDialog shouldShowPayment={shouldShowPayment} title={title} dispatch={dispatch}
                                            setOpenModal={setOpenModal} setShowBilling={setShowBilling}
                                            userDataObject={userDataObject} openModal={openModal}
                                            areAnyOfTheContractorsPaid0={areAnyOfTheContractorsPaid0}
                                            showToast={showToast} contractorsReadyToFile={contractorsReadyToFile}
                                            accountTypeViewing={accountTypeViewing} showPayments={showPayments}/>}


        </div>

    );
};

export default SummaryModal;

SummaryModal.propTypes = {
    showToast: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    accountTypeViewing: PropTypes.string.isRequired,
    contractorsReadyToFile: PropTypes.array.isRequired,
    setOpenModal: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    showPayments: PropTypes.bool.isRequired
};

