import React, {useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import SummaryTable from "./SummaryTable";
import loadingGif from "../../../assets/loadingGreen.gif";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {enums} from "../../../utils/enums";
import Dialog from "@material-ui/core/Dialog";
import {fileToIRS, request1099FromAccountant} from "../../../utils/sharedFunctions";
import firebaseApp from "../../../Firebase/firebaseApp";
import PropTypes from "prop-types";
import SummaryModal from "./SummaryModal";
import useTheme from "@material-ui/core/styles/useTheme";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";

const PaymentDialog = ({
                           openModal, setOpenModal, title, areAnyOfTheContractorsPaid0, contractorsReadyToFile,
                           accountTypeViewing, shouldShowPayment, setShowBilling, userDataObject, dispatch, showToast
                       }) => {
    const theme = useTheme()
    const classes = useStyles();
    const history = useHistory();
    const [processing, setProcessing] = useState(false)

    async function handleConfirm() {

        contractorsReadyToFile.forEach(contractor => {

            if (!userDataObject.requestedFilings) {
                userDataObject.requestedFilings = []
            }

            const indexOfContractor = userDataObject.requestedFilings.findIndex(value => contractor.uid === value)
            if (indexOfContractor === -1) {
                userDataObject.requestedFilings = [...userDataObject.requestedFilings, contractor.uid]
            }
        })

        try {
            await request1099FromAccountant(dispatch, userDataObject, await firebaseApp.auth().currentUser.getIdToken());

            showToast('success', 'Request Sent!')
            setOpenModal(false)
        } catch (e) {
            showToast('error', 'Failed to request!')
        }
    }

    async function handleSubmitToIrs() {
        try {
            setProcessing(true)
            await fileToIRS(await firebaseApp.auth().currentUser.getIdToken())
            showToast("success", "Successfully filed to the IRS!")
            setOpenModal(false)
            setProcessing(false)
        } catch (e) {
            showToast("error", "Something went wrong, please try again!")
            setProcessing(false)
        }
    }

    return (
        <Dialog
            maxWidth={false}
            open={openModal}
            onClose={() => setOpenModal(false)}
            onBackdropClick={() => setOpenModal(false)}
            aria-labelledby="form-dialog-title">
            <section>
                <DialogTitle id="form-dialog-title">
                    <strong>Select Payment Method</strong>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction={"row"} style={{marginTop: 20}}>
                        <Grid item container xs={6} direction={"column"} alignItems={"center"} alignContent={"center"}>
                            <Grid item>
                                <Typography><strong>Required Credits</strong></Typography>
                            </Grid>
                            <Grid item>
                                <Typography style={{
                                    // background: 'lightblue',
                                    borderRadius: '50%',
                                    width: '100px',
                                    height: '100px',
                                    textAlign: 'center',
                                    borderWidth: 3,
                                    borderStyle: 'dashed',
                                    borderColor: theme.palette.primary.main,
                                    verticalAlign: 'middle',
                                    padding: '40px 0'
                                }}><strong>{contractorsReadyToFile.length}</strong></Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={6} direction={"column"} alignItems={"center"} alignContent={"center"}>
                            <Grid item>
                                <Typography><strong>Available Credits</strong></Typography>
                            </Grid>
                            <Grid item>
                                <Typography style={{
                                    // background: 'lightblue',
                                    borderRadius: '50%',
                                    width: '100px',
                                    height: '100px',
                                    textAlign: 'center',
                                    borderWidth: 3,
                                    borderStyle: 'dashed',
                                    borderColor: theme.palette.primary.main,
                                    verticalAlign: 'middle',
                                    padding: '40px 0'
                                }}><strong>{userDataObject.billing.credits}</strong></Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} direction={"column"} alignContent={"center"} alignItems={"center"}
                          justify={"center"} spacing={1} style={{marginTop: 40}}>
                        {userDataObject.billing.credits >= contractorsReadyToFile.length
                        && <Grid item xs={12} style={{textAlign: "center"}}>
                            <Typography color={"primary"} variant={"h4"}><strong>Good News!</strong></Typography>
                            <Typography>Looks like you have enough credits to file</Typography>
                        </Grid>}

                        {!userDataObject.billing.credits >= contractorsReadyToFile.length
                        && <Grid item xs={12} style={{textAlign: "center"}}>
                            <Typography color={"primary"} variant={"h4"}><strong>Uh Oh!</strong></Typography>
                            <Typography>Looks like you don't have enough credits to file. Click Get credits to buy
                                some</Typography>
                        </Grid>}
                        <Grid item>
                            <Button className={classes.button}
                                    disabled={!userDataObject.billing.credits >= contractorsReadyToFile.length}
                                    fullWidth
                                    variant={"contained"} color={"primary"}
                                    onClick={() => handleSubmitToIrs()}
                            >
                                {processing ? "Processing..." : "File To IRS"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className={classes.button} variant={"outlined"}
                                    fullWidth
                                    color={!userDataObject.billing.credits >= contractorsReadyToFile.length ? "primary" : "secondary"}
                                    onClick={() => history.push('billing')}
                            >
                                Get credits
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className={classes.button} fullWidth variant={"outlined"} disabled={true}>Charge
                                Clients (Coming Soon)</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)} color="secondary">
                        Cancel
                    </Button>

                    {!shouldShowPayment()
                    &&
                    <Button
                        disabled={areAnyOfTheContractorsPaid0()}
                        onClick={async () => {
                            switch (accountTypeViewing) {
                                case enums.ACCOUNTANT:
                                    break
                                case enums.ACCOUNTANTS_CLIENT:
                                    await handleConfirm()
                                    break
                            }
                        }}
                        variant={'contained'}
                        color="primary">
                        Submit
                    </Button>
                    }

                </DialogActions>
            </section>


        </Dialog>
    );
};

export default PaymentDialog;

const useStyles = makeStyles(theme => ({
    button: {
        width: 200,
    },
}))

PaymentDialog.propTypes = {
    showToast: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    accountTypeViewing: PropTypes.string.isRequired,
    contractorsReadyToFile: PropTypes.array.isRequired,
    setOpenModal: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    showPayments: PropTypes.bool.isRequired,
    areAnyOfTheContractorsPaid0: PropTypes.func.isRequired,
    shouldShowPayment: PropTypes.func.isRequired,
    setShowBilling: PropTypes.func.isRequired,
    userDataObject: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};
