import React, {useContext, useState} from 'react';
import Congratulations from "./Congratulations";
import Typography from "@material-ui/core/Typography";
import UserInformationForm from "../../../utils/modals/UserInformationForm";
import Toast from "../../../utils/sharedComponents/Toast";
import {AppContext} from "../../../context/AppContext";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {enums} from "../../../utils/enums";
import {useHistory} from "react-router";
import firebaseApp from "../../../Firebase/firebaseApp";
import {makeStyles} from "@material-ui/core/styles";
import {updateUserInDb} from "../../../utils/sharedFunctions";
import {completePayorProfile} from "../../../utils/sharedFunctions";

const FirstTimeLogIn = ({showToast}) => {
    const {state: {continueToStep2, userDataObject}, dispatch} = useContext(AppContext);
    const [clientObject, setClientObject] = useState(null)
    const classes = useStyles();

    async function handleGoToDashboard() {
        const newClientObject = clientObject
        newClientObject.hasConfirmedInformation = true
        try {
            await completePayorProfile(dispatch, newClientObject, await firebaseApp.auth().currentUser.getIdToken())
            showToast("success", 'your information has been updated')
        } catch (e) {
            showToast("error", 'Sorry, something went wrong, please try again!')
        }
        dispatch({updateAccountantsClient: true})
    }


    return (
        <div>
            {!continueToStep2
            && <Congratulations/>
            }

            {continueToStep2
            &&
            <Grid container spacing={2}
                  justify={"center"} alignItems={"center"} alignContent={"center"} direction={"column"}>
                <Grid item xs={8}>
                    <Typography variant={'h2'}><strong>Verify your information</strong></Typography>
                </Grid>
                <Grid item xs={8}>
                    <UserInformationForm
                        clientTypeToAdd={enums.ACCOUNTANTS_CLIENT}
                        setClientObject={setClientObject}
                        existingUserDataObject={userDataObject}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Button className={classes.continueButton} variant={"contained"}
                            onClick={handleGoToDashboard}>
                        Confirm and go to Dashboard</Button>
                </Grid>
            </Grid>
            }


        </div>
    );
};

export default FirstTimeLogIn;

const useStyles = makeStyles((theme) => ({
    continueButton: {
        // width: '60%',
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        margin: 10,
        borderRadius: 30
    },
}))
