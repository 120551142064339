import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import UserInformationForm from "../../utils/modals/UserInformationForm";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import UpdateSingleNumericFieldModal from "../../utils/modals/UpdateSingleNumericFieldModal";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {enums} from "../../utils/enums";
import {AppContext} from "../../context/AppContext";
import {getQueryStringParams, updateUserInDb} from "../../utils/sharedFunctions";
import * as firebase from "firebase";
import {useLocation} from "react-router";

const AccountantProfile = ({
                               showToast,
                               setClientObject,
                               userDataObject,
                               handleChangeInformation,
                               shouldShowRequiredFields
                           }) => {
    const classes = useStyles();
    const [priceFor1099, setPriceFor1099] = useState(25)
    const {dispatch} = useContext(AppContext)
    const location = useLocation()


    async function handleCheckBoxChange(personToPay) {
        userDataObject.personToPay = personToPay
        dispatch({userDataObject: userDataObject})
        try {
            await updateUserInDb(dispatch, userDataObject, await firebase.auth().currentUser.getIdToken())
            showToast("success", "Payment type successfully elected")
        } catch (e) {
            showToast("error", "Something went wrong, please try again")
        }
    }

    return (
        <Grid container direction={"column"} alignContent={"center"} alignItems={"center"} justify={"center"}>
            <Grid item>
                <Typography>Accountant User Profile</Typography>
            </Grid>

            <Grid item>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}><strong>Your Information</strong></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction={"column"} alignItems={'center'} alignContent={"center"}>
                            <Grid item>
                                <UserInformationForm showToast={showToast}
                                                     clientTypeToAdd={userDataObject.accountType}
                                                     setClientObject={setClientObject}
                                                     existingUserDataObject={userDataObject}
                                                     shouldShowRequiredFields={shouldShowRequiredFields}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant={"contained"} color={"primary"}
                                        style={{marginTop: 30}}
                                        onClick={() => handleChangeInformation()}>
                                    Change information</Button>
                            </Grid>

                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}><strong>Payments Information</strong></Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <Grid container item direction={"column"}>
                            <Grid item>
                                <Typography variant={"h4"}>Get Paid - Connect Stripe Account</Typography>
                            </Grid>
                            <Grid item style={{marginTop: 20}}>
                                <Typography variant={"body1"}>We use <a href={"https://stripe.com/"}
                                                                        target={"_blank"}>Stripe</a> for payment
                                    processing - That means you
                                    set how much you want a 1099 to cost, we deduct processing fees and the money
                                    rolls in </Typography>
                            </Grid>
                            <Grid item>

                                <Grid container style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginTop: 30,
                                    marginBottom: 20
                                }}
                                      spacing={2}>
                                    <Grid item>
                                        <Typography>Client charge per 1099 </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            margin="dense"
                                            id="priceFor1099"
                                            label={"Price per 1099"}
                                            type="text"
                                            variant={"outlined"}
                                            required
                                            value={priceFor1099}
                                            onChange={(e) => setPriceFor1099(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography>(Most accountants charge 25$ per 1099)</Typography>
                                    </Grid>

                                    <Grid item container direction={"column"}>
                                        <Grid item>
                                            <Typography variant={"h5"}>Collection Options</Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant={"body1"}>You can choose between two payment collection
                                                options</Typography>
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={enums.CLIENT_PAYS === userDataObject.personToPay}
                                                    onChange={() => handleCheckBoxChange(enums.CLIENT_PAYS)}
                                                    name="checkedA"/>}
                                                label="Collect payments from the client automatically"
                                            />
                                            <Typography>
                                                eFileBuddy collects the payments from your clients via Stripe, and send
                                                your portion
                                                to the bank account you set up after deducting the buddy fee. To get
                                                started please
                                                click button below to set up your Stripe account
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            {enums.CLIENT_PAYS === userDataObject.personToPay
                                            && <Grid item style={{marginTop: 20}}>
                                                <Button color={"primary"} variant={"contained"}>Get Paid - Set Up
                                                    Stripe</Button>
                                            </Grid>}
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={enums.ACCOUNTANT_PAYS === userDataObject.personToPay}
                                                    onChange={() => handleCheckBoxChange(enums.ACCOUNTANT_PAYS)}
                                                    name="checkedA"/>}
                                                label={userDataObject.companyName + ' will pay up front and handle our own client billing '}
                                            />
                                            <Typography>p
                                                eFileBuddy can collect the payments from your clients via Stripe, and
                                                send your portion
                                                to the bank account you set up after deducting the buddy fee. This
                                                option will allow your clients
                                                to file for free and it will charge you the 4.99 fee when you approve
                                                clients filings
                                            </Typography>
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>


                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

        </Grid>
    );
};

export default AccountantProfile;

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

AccountantProfile.propTypes = {
    showToast: PropTypes.func.isRequired,
    setClientObject: PropTypes.func.isRequired,
    handleChangeInformation: PropTypes.func.isRequired,
    userDataObject: PropTypes.object.isRequired
}
