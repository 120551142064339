import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import Typography from "@material-ui/core/Typography";
import {AppContext} from "../../context/AppContext";
import Grid from "@material-ui/core/Grid";
import Menu from "../Menu/Menu";
import {makeStyles} from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import useGetUserData from "../../utils/hooks/useGetUserData";
import CssBaseline from "@material-ui/core/CssBaseline";
import ClientDetailsTop from "./ClientDetailsTop";
import Toast from "../../utils/sharedComponents/Toast";
import AppBarSpacer from "../../utils/sharedComponents/AppBarSpacer";
import useProtectRoute from "../../utils/hooks/useProtectRoute";
import {enums} from "../../utils/enums";
import AccountantsClientMenuListItems from "../AccountantsClientDashboard/accountantsClientMenuListItems";
import AccountantMenuListItems from "../AccountantDashboard/accountantMenuListItems";
import '../../utils/animations.css'
import AddClientModal from "../../utils/modals/AddClientModal";
import {updateUserInDb} from "../../utils/sharedFunctions";
import firebase from "firebase/app";
import PriorFilingsShowingContractorData from "./PriorFilings/PriorFilingsShowingContractorData";
import PriorFilingsShowingAggregatedContractorData from "./PriorFilings/PriorFilingsShowingAggregatedContractorData";

const UserDetailsView = () => {
    useProtectRoute()
    useGetUserData()

    const [toastStatus, setToastStatus] = useState(null);
    const classes = useStyles();
    let {uid} = useParams();
    const {state: {userDataObject, listOfUsersClients}, dispatch} = useContext(AppContext);
    const [client, setClient] = useState(null)
    const [clientToEdit, setClientToEdit] = useState(null)

    useEffect(() => {
        listOfUsersClients &&
        setClient(listOfUsersClients.filter(client => client.uid === uid)[0])
    })

    function showToast(toastType, toastMessage) {
        setToastStatus({
            toastType: toastType,
            toastMessage: toastMessage,
            date: Date()
        })
    }


    function deduceListToRender() {
        switch (userDataObject.accountType) {
            case "ACCOUNTANTS_CLIENT":
                return <AccountantsClientMenuListItems/>
            case "ACCOUNTANT":
                return <AccountantMenuListItems/>
        }
    }

    async function handleClientEdit(updatedClientObject) {
        try {
            await updateUserInDb(dispatch, updatedClientObject, await firebase.auth().currentUser.getIdToken())
            dispatch({shouldOpenAddClientModal: false})
            showToast('success', 'User Updated!')
        } catch (e) {
            showToast('error', 'Something went wrong, please try again!')
        }

    }

    function clientHasContractorsWithFilings() {
        if (!client.contractorFilingsSentToIRS) return false
        else return client.contractorFilingsSentToIRS && client.contractorFilingsSentToIRS.length > 0;
    }

    function generatePriorFilings() {
        if (client.accountType === enums.CONTRACTOR
            && client.priorFilings) {
            return <PriorFilingsShowingContractorData client={client}/>
        } else if (client.accountType === enums.ACCOUNTANTS_CLIENT
            && clientHasContractorsWithFilings(client)) {
            return <PriorFilingsShowingAggregatedContractorData client={client} showToast={showToast}/>
        } else return null

    }

    return (
        <div className={classes.root}>
            {userDataObject
            && <Menu listOfItemsToRender={deduceListToRender()}/>}
            <AppBarSpacer/>

            <CssBaseline/>
            {client ?
                <Fade in timeout={2000}>
                    <Grid container direction={"column"} alignContent={"flex-start"} spacing={2}>
                        <Grid item xs={12} style={{width: '100%'}}>
                            <ClientDetailsTop clientToShow={client} showToast={showToast}
                                              setClientToEdit={setClientToEdit}/>
                        </Grid>

                        <section style={{margin: 10, marginTop: 40}}>
                            <Grid item xs={12}>
                                <Typography variant={"h4"}><strong>Prior Filings</strong></Typography>
                            </Grid>
                            {client &&
                            <Grid item xs={12}>
                                {generatePriorFilings()
                                    ? generatePriorFilings()
                                    : <Typography variant={"body1"}>Any prior filings will appear here</Typography>
                                }
                            </Grid>
                            }
                        </section>

                    </Grid>
                </Fade>
                : null}

            {clientToEdit
            &&
            <AddClientModal clientTypeToAdd={clientToEdit.accountType} showToast={showToast}
                            title={"Edit Information"}
                            onSubmitHandlerFunction={handleClientEdit}
                            clientToEdit={clientToEdit}
                            onCancelHandlerFunction={() => {
                                setClientToEdit(null)
                                dispatch({shouldOpenAddClientModal: true})
                            }}
                            warningMessage={"WARNING! You are editing client fields!"}
            />}

            {toastStatus ? <Toast key={toastStatus.date} toastStatus={toastStatus}/> : null}
        </div>
    );
};

export default UserDetailsView;

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 10,
        backgroundColor: theme.palette.neutral.gray,
        padding: theme.spacing(1),
        minWidth: '100vw',
        height: '100vh'
    },
    appBarSpacer: theme.mixins.toolbar,

}))

