import * as React from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const options = ['Option 1', 'Option 2'];

export default function ControllableStates({options, value, setValue, shouldShowRequiredFields}) {
    // const [value, setValue] = React.useState(options[0]);
    // const [inputValue, setInputValue] = React.useState('');

    return (
        <div>
            {/*<div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>*/}
            {/*<div>{`inputValue: '${inputValue}'`}</div>*/}
            {/*<br />*/}
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                // inputValue={inputValue}
                // onInputChange={(event, newInputValue) => {
                //     setInputValue(newInputValue);
                // }}
                id="controllable-states-demo"
                options={options}
                sx={{ width: 300 }}
                renderInput={(params) =>
                    <TextField {...params} label="State" error={shouldShowRequiredFields && value === ''}/>}
            />
        </div>
    );
}
