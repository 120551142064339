import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {AppContext} from "../../context/AppContext";
import PropTypes from "prop-types";
import copy from 'copy-to-clipboard'
import {clientUrl} from "../../config";
import {enums} from "../../utils/enums";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ArchiveIcon from "@material-ui/icons/Archive";
import {archiveClientInDb} from "../../utils/sharedFunctions";
import * as firebase from "firebase";
import firebaseApp from "../../Firebase/firebaseApp";
import {useHistory} from "react-router";

function createData(fieldName, fieldValue, fat, carbs, protein) {
    return {name: fieldName, calories: fieldValue, fat, carbs, protein};
}


export default function UserDetailsTable({clientToShow, showToast, setClientToEdit}) {
    const {dispatch} = useContext(AppContext);
    const classes = useStyles();
    const history = useHistory()

    function copyLinkToClipBoard() {
        const deduceUrl = () => {
            switch (clientToShow.accountType) {
                case "CONTRACTOR":
                    return '/contractorform/'
                case "ACCOUNTANTS_CLIENT":
                    return '/client/'
                default:
                    return '';
            }
        };

        const url = deduceUrl();

        if (!url) {
            return;
        }

        copy(clientUrl + url + clientToShow.uid)
        showToast('success', 'Link copied to clipboard!')

    }

    function createRows() {
        let rows = []
        rows.push(
            createData('Name', clientToShow.firstName + " " + clientToShow.lastName),
            createData('Email', clientToShow.email),
            createData('EIN', clientToShow.ein),
            createData('Address', clientToShow.addressLine1 + " " + clientToShow.city))

        if (enums.CONTRACTOR === clientToShow.accountType) {
            rows.push(createData('Contractor Form Link', <a onClick={() => {
                copyLinkToClipBoard()
            }}>click to copy form link</a>))
        }
        rows.push(createData('Edit Information', <a onClick={() => {
            setClientToEdit(clientToShow)
            dispatch({shouldOpenAddClientModal: true})
        }}>click to edit information</a>))

        // rows.push(createData('Archive Client',
        //     <a onClick={async () => {
        //         try {
        //             await archiveClientInDb(await firebase.auth().currentUser.getIdToken(), clientToShow.uid)
        //             showToast('success', 'User archived')
        //             await firebaseApp.auth().signOut()
        //             history.push('/login')
        //
        //         } catch (e) {
        //             showToast('error', "Something went wrong, please try again!")
        //         }
        //     }}>click to archive client</a>))
        return rows
    }

    return (
        <TableContainer component={Paper} className={classes.table}>
            <Table size="small" aria-label="a dense table">
                <TableBody>
                    {createRows().map((row) => (
                        <TableRow key={row.name}>
                            <TableCell className={classes.tableCell} scope="row" align={"left"}>{row.name}</TableCell>
                            <TableCell className={classes.tableCell} align="left">{row.calories}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


const useStyles = makeStyles(theme => ({
    table: {
        width: '100%',
        minHeight: '25vh',
        // overflow: 'hidden',
        padding: 0
    },
    tableCell: {
        border: 0
    }

}));

UserDetailsTable.propTypes = {
    clientToShow: PropTypes.object.isRequired,
    showToast: PropTypes.func,
    setClientToEdit: PropTypes.func
};

