import React, {useContext, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import PricingPackages from "./PricingPackages";
import Container from "@material-ui/core/Container";
import Menu from "../Menu/Menu";
import Toast from "../../utils/sharedComponents/Toast";
import CheckoutModal from "./CheckoutModal";
import firebase from "firebase";
import {useHistory} from "react-router";
import useProtectRoute from "../../utils/hooks/useProtectRoute";
import {AppContext} from "../../context/AppContext";
import Button from "@material-ui/core/Button";
import {useTheme} from "@material-ui/core";

const Billing = () => {
    const classes = useStyles();
    const [toastStatus, setToastStatus] = useState(null);
    const [openModal, setOpenModalReal] = useState(false);
    const [packageToBuy, setPackageToBuy] = useState("");
    const [showPromoCode, setShowPromoCode] = useState(false);
    const {state: {userDataObject}} = useContext(AppContext);
    const theme = useTheme()
    useProtectRoute()

    function setOpenModal(bool) {
        if (bool === false) {
            setPackageToBuy("")
        }
        setOpenModalReal(bool)
    }

    function showToast(toastType, toastMessage) {
        setToastStatus({
            toastType: toastType,
            toastMessage: toastMessage,
            date: Date()
        })
    }

    if (!userDataObject) {
        return null
    }


    return (
        <div className={classes.root}>
            <Container style={{backgroundColor: theme.palette.neutral.gray}}>
                <Menu/>
                <Grid container direction={"column"} alignContent={"center"} alignItems={"center"} justify={"center"}
                      style={{textAlign: "center", marginTop: 80}}>

                    {userDataObject.billing.planActive
                    && <section>
                        <Grid item>
                            <Typography variant={"h1"}>Your Plan</Typography>
                            <Typography variant={"h5"} style={{marginTop: 30}}>Congratulations, you are
                                on {userDataObject.billing.planTier} plan</Typography>
                        </Grid>

                        <Grid item>
                            <Typography>Your plan is active
                                until: {new Date(userDataObject.billing.expirationDate).toString()}</Typography>
                        </Grid>

                    </section>}

                    {userDataObject.billing.planActive
                    && parseFloat(userDataObject.billing.credits) === 0
                    && <section>
                        <Grid item style={{marginTop: '20vh'}}>
                            <Typography variant={"h2"}>Uh Oh!</Typography>
                            <Typography variant={"h5"}>You're out of credits, buy more by clicking the button
                                below</Typography>
                        </Grid>
                    </section>}

                    {!userDataObject.billing.planActive
                    && <section>
                        <Grid item>
                            <Typography variant={"h2"}>Select Your Credits Package</Typography>
                            <Typography variant={"h5"} style={{marginBottom: 20}}>
                                We are excited to offer you our beta launch pricing special
                            </Typography>
                        </Grid>

                        <Grid item container direction={"row"}
                              className={classes.item} spacing={1}>
                            <Grid item>
                                <Button variant={"contained"} onClick={() => setOpenModal(true)}>Buy Credits</Button>
                            </Grid>
                            <Grid item className={classes.item}>
                                <Typography>for 10$ per credit</Typography>
                            </Grid>
                        </Grid>


                        <Grid item container direction={"row"} style={{marginTop: 40}} spacing={1} alignContent={"flex-start"}>
                            <Grid item xs={5} className={classes.item}>
                                <Typography>Gold plan for the price of bronze!</Typography>
                            </Grid>
                            <Grid item xs={7} className={classes.item}>
                                <Button color={"primary"} variant={"contained"} onClick={() => {
                                    setPackageToBuy('goldSpecial')
                                    setOpenModal(true)
                                }}>
                                    Buy Gold Plan For $99</Button>
                            </Grid>

                            <Grid item xs={5} className={classes.item}>
                                <Typography>Have a promo code?</Typography>
                            </Grid>
                            <Grid item xs={7} className={classes.item}>
                                <Button color={"primary"} variant={"contained"} onClick={() => {
                                    setShowPromoCode(true)
                                    setOpenModal(true)
                                }}>
                                    Get Free Credits</Button>
                            </Grid>
                        </Grid>

                        <Grid item style={{marginTop: 40}}>
                            <PricingPackages setOpenModal={setOpenModal} setPackageToBuy={setPackageToBuy}/>
                        </Grid>
                    </section>}

                    {userDataObject.billing.planActive && <Grid item style={{marginTop: 40}}>
                        <Button variant={"contained"} color={"primary"} onClick={() => setOpenModal(true)}>Buy more
                            credits</Button>
                    </Grid>}

                </Grid>

                <CheckoutModal openModal={openModal} setOpenModal={setOpenModal} showToast={showToast}
                               packageToBuy={packageToBuy} showPromoCode={showPromoCode}/>

                {toastStatus ? <Toast toastStatus={toastStatus} key={toastStatus.date}/> : null}
            </Container>
        </div>
    );
};

export default Billing;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.neutral.gray
    },
    item:{display: 'flex',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row'}

}));

